<modal
  :class="'max-height-100-145'"
  title="Refill Voucher Amount"
  :handler="true"
  @modalHandler="this.onCancel"
  :cssClass="'c-modal-small'"
>
  <template v-slot:content>
    <div>
      <div class="text-start">
        <label for="">Enter the amount you want to refill *</label>
        <numericTextBox
          v-model="this.voucherAmount"
          :min="0.01"
          :max="500"
          :format="'n2'"
          :step="0.01"
          @input="validateInput"
        />
      </div>
    </div>
  </template>
  <template v-slot:footer>
    <div class="d-flex justify-content-between">
      <div class="me-1 w-50-p">
        <kbutton @click="this.onCancel" :class="'w-100'">Cancel</kbutton>
      </div>
      <div class="ms-1 w-50-p">
        <kbutton
          @click="this.onSubmitHandler"
          :disabled="!this.voucherAmount "
          :theme-color="'primary'"
          :class="'w-100'"
          >Refill</kbutton
        >
      </div>
    </div>
  </template>
</modal>

<AppSnackBar
  :message="this.notifyMessage"
  :notificationType="this.notifyType"
  v-if="this.isNotify"
></AppSnackBar>
