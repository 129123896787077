<template src="./AddUnasignedVoucheModal.html"></template>

<script>
import { Button } from "@progress/kendo-vue-buttons";
import AppLoader from "@/components/common/AppLoader/AppLoader.vue";
import modal from "@/components/common/AppModal/AppModal.vue";
import { NumericTextBox } from "@progress/kendo-vue-inputs";
import { DatePicker } from "@progress/kendo-vue-dateinputs";
import AppSnackBar from "@/components/common/AppSnackBar/AppSnackBar.vue";
import { Input } from "@progress/kendo-vue-inputs";
import { DropDownList } from "@progress/kendo-vue-dropdowns";
export default {
  props: {
    payloadForaddUnassignedVoucher: {
      type: Object,
    },

    voucherPrefixValue: {
      type: Object,
    },
  },
  components: {
    kbutton: Button,
    "app-loader": AppLoader,
    modal,
    numericTextBox: NumericTextBox,
    datePicker: DatePicker,
    AppSnackBar,
    "k-input": Input,
    dropdownlist: DropDownList,
  },
  data() {
    return {
      voucherQuantity: 10,
      voucherAmount: 0,
      voucherExpiryDate: null,
      voucherPrefix: this.voucherPrefixValue,
      notifyMessage: "",
      notifyType: "",
      isNotify: false,
      voucherStatusesValues: [
        { id: 1, text: "Inactive" },
        { id: 2, text: "Active" },
        // { id: 3, text: "Suspend" },
        // { id: 4, text: "Redeemed" },
        // { id: 5, text: "Expired" },
      ],
      voucherStatusValue: { id: 2, text: "Active" },
      currentDate: new Date(),
    };
  },
  mounted() {
    this.voucherAmount = this.payloadForaddUnassignedVoucher.amount;
    this.voucherExpiryDate = new Date(
      this.payloadForaddUnassignedVoucher.expiryDate
    );
  },
  computed: {
    newDatePlusOne() {
      const currentDate = new Date(this.currentDate);
      currentDate.setDate(currentDate.getDate() + 1);
      return currentDate;
    },
  },
  methods: {
    modalHandler() {
      this.$store.commit("TOGGLE_ADD_VOUCHER_MODAL_VISIBILITY", false);
    },
    updateExpiryDate(event) {
      this.voucherExpiryDate = event.target.value;
    },
    showNotification(message, type) {
      this.notifyMessage = message;
      this.notifyType = type;
      this.isNotify = true;
      setTimeout(
        () => (
          (this.notifyMessage = ""),
          (this.notifyType = "success"),
          (this.isNotify = false)
        ),
        4000
      );
    },
    isPastDate(date) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      date.setHours(0, 0, 0, 0);
      return date < today;
    },
    dataValidation() {
      let value = true;

      function onlyLettersAndNumbers(str) {
        return /^[A-Za-z0-9]*$/.test(str);
      }

      if (this.isPastDate(this.voucherExpiryDate)) {
        this.showNotification("Invalid voucher expiry date.", "warning");
        value = false;
      }

      if (this.voucherPrefix && !onlyLettersAndNumbers(this.voucherPrefix)) {
        this.showNotification(
          "Voucher prefix should contain only letters and numbers.",
          "warning"
        );
        value = false;
      }

      if (this.voucherPrefix && this.voucherPrefix.length > 6) {
        this.showNotification(
          "Voucher prefix can not be more than 6 characters.",
          "warning"
        );
        value = false;
      }

      if (this.voucherQuantity < 1 || this.voucherQuantity > 500) {
        this.showNotification(
          "Voucher quantity should be between 1 and 500.",
          "warning"
        );
        value = false;
      }

      if (this.voucherAmount < 1 || this.voucherAmount > 500) {
        this.showNotification(
          "Voucher amount should be between 1 and 500.",
          "warning"
        );
        value = false;
      }

      return value;
    },
    handlevoucherStatusValue(e) {
      this.voucherStatusValue = e.value;
    },
    addVoucherCodeHandler() {
      if (this.dataValidation()) {
        const payload = {
          clubId: this.payloadForaddUnassignedVoucher.clubId,
          voucherCampaignId:
            this.payloadForaddUnassignedVoucher.voucherCampaignId,
          voucherQuantity: this.voucherQuantity,
          voucherStatusValue: this.voucherStatusValue.text,
          voucherAmount: this.voucherAmount,
          voucherExpiry: this.voucherExpiryDate,
          limit: 100,
          offset: 0,

          ...(this.voucherPrefix && { voucherPrefix: this.voucherPrefix }),
        };
        this.$store.commit("TOGGLE_ADD_VOUCHER_MODAL_VISIBILITY", false);
        this.$store.dispatch("generateUnassignedVoucherCode", payload);
      }
    },
  },
};
</script>

<style></style>
