<modal
  cssClass="c-modal-lg"
  title="Select Recipient From Voucher Audience"
  :handler="modalVisible"
  :footer="false"
  @modalHandler="modalHandler"
  :isLoading="isLoadingModal"
>
  <template v-slot:content>
    <AppSnackBar
      :message="notifyMessage"
      :notificationType="notifyType"
      v-if="isNotify"
    ></AppSnackBar>
    <!-- <app-loader v-if="isLoadingModal"></app-loader> -->
    <div class="">
      <div v-if="!isOpenConfigureFields">
        <h1>{{part}}</h1>

        <Grid
          ref="grid"
          :class="'max-height-100-250 select-recipient-table'"
          :data-items="allAudienceData.items"
          :take="take"
          :skip="skip"
          :total="total"
          :pageable="gridPageable"
          :selectable="true"
          :selected-field="selectedField"
          :columns="columns"
          @selectionchange="onSelectionChange"
          @datastatechange="dataStateChange"
          @headerselectionchange="onHeaderSelectionChange"
        >
          <toolbar>
            <div class="d-flex flex-wrap justify-content-between w-100">
              <div class="d-flex flex-wrap align-items-center">
                <div
                  class="search-box-wrap me-4 mb-1 width-220 d-flex align-items-center"
                >
                  <span class="k-icon k-i-zoom"></span>
                  <span class="k-textbox k-grid-search k-display-flex">
                    <k-input
                      :class="'mb-0 width-220'"
                      placeholder="Search"
                      @input="debounceSearch"
                    >
                    </k-input>
                  </span>
                </div>
              </div>
            </div>
          </toolbar>

          <template v-slot:name="{props}">
            <td>{{props.dataItem.firstName}} {{props.dataItem.lastName}}</td>
          </template>

          <template v-slot:assign="{props}">
            <td>
              <div @click="assignStatusHandler(props)" class="me-3">
                <k-switch
                  :size="'small'"
                  @change="switchStatushandler"
                  :checked="audienceId == props.dataItem.id"
                />
              </div>
            </td>
          </template>

          <GridNoRecords> No audience found. </GridNoRecords>
        </Grid>
      </div>
    </div>

    <dialog-actions-bar>
      <div class="d-flex justify-content-end">
        <kbutton @click="modalHandler" class="me-1">Cancel</kbutton>
        <kbutton
          @click="assignVoucherHandler"
          :disabled="!audienceId"
          :theme-color="'primary'"
          class="ms-1"
        >
          Done</kbutton
        >
      </div>
    </dialog-actions-bar>
  </template>
</modal>
