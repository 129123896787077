<template src="./UploadDataForVoucherModal.html"></template>

<script>
import { Dialog, DialogActionsBar } from "@progress/kendo-vue-dialogs";
import { Button } from "@progress/kendo-vue-buttons";
import { Input } from "@progress/kendo-vue-inputs";
import { Form } from "@progress/kendo-vue-form";
import { Upload } from "@progress/kendo-vue-upload";
import myUpload from "vue-image-crop-upload";
import modal from "../../../../common/AppModal/AppModal.vue";
import { configuration } from "@/configurationProvider";

const fileStatuses = [
  "UploadFailed",
  "Initial",
  "Selected",
  "Uploading",
  "Uploaded",
  "RemoveFailed",
  "Removing",
];

export default {
  components: {
    "k-dialog": Dialog,
    "dialog-actions-bar": DialogActionsBar,
    kbutton: Button,
    "k-input": Input,
    "k-form": Form,
    upload: Upload,
    myUpload,
    modal,
  },
  computed: {},
  data: function () {
    const url = configuration.apiBaseUrl;

    return {
      visibleDialog: true,
      show: true,
      url,
      uploadDocument: url + "/api/v1/Document?clubId=" + this.$route.params.id,
      importMembersDocId: "",
      removeDocumentRes: "",
      importFileName: "",
      files: [],
      events: [],
      filePreviews: {},
      allowedExtensions: [".csv", ".xls", ".xlsx"],
      isDisabled: false,
      validationErrors: "",
    };
  },

  methods: {
    onStatusChange(event) {
      this.validationErrors = "";
      this.isDisabled = true;
      this.importMembersDocId = event.response.response.id;
      const file = event.affectedFiles[0];
      this.documentId = event.response.response.id;
      this.importFileName = event.response.response.originalName;
      this.files = event.newState;
      this.events.push(
        `File '${file.name}' status changed to: ${fileStatuses[file.status]}`
      );
    },
    openMemberImportModal() {
      this.$store.commit("IMPORT_MODAL_VISIBLE", true);
    },
    removeDocument() {
      this.$store.dispatch("deleteDocuments", this.importMembersDocId);
      this.importMembersDocId = "";
      this.importFileName = "";
      this.isDisabled = false;
    },
    parentModalhandler() {
      this.$emit("modalHandler", false);
    },
    handleFieldMapping() {
      this.$emit("callBackDataForDigitalPass", this.documentId);
      this.$emit("callBackDataForDigitalPassAdd", true);
      this.$emit("callBackDataForDigitalPassDocId", this.documentId);
      this.$emit("uploadedFileForDigitalPass", this.documentId);
    },
    modalHandler() {
      this.$emit("closeUploadDataModal");
      this.$emit("closeUploadDataModalAdd");
    },
    onAdd(event) {
      if (event.affectedFiles[0]?.validationErrors) {
        this.validationErrors =
          "File type not supported. Supported file types are CSV, XLS and XLSX";
      }
    },
    isNameLimit(name) {
      ;
      return name.length <= 50 ? name : name.substring(0, 50) + "...";
    },
  },
};
</script>
