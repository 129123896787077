<template src="./GeneratingVoucherCodeSnackbar.html"></template>

<script>
import modal from "@/components/common/AppModal/AppModal.vue";
import { Button } from "@progress/kendo-vue-buttons";

export default {
  components: {
    modal,
    kbutton: Button,
  },

  data: function () {
    return {};
  },

  computed: {},

  methods: {
    modalHandler(data) {
      this.$store.commit(
        "SET_GENERATING_VOUCHER_CODE_SNACKBAR_VISIBILITY",
        false
      );
    },
  },
};
</script>

<style></style>
