<template src="./ManageRecipients.html"></template>
<script>
import { Button, ButtonGroup } from "@progress/kendo-vue-buttons";
import SelectMemberModal from "../../../clubDetails/digitalPass/selectMemberModal/SelectMemberModal.vue";
import ManageRecipientsTable from "../../../clubDetails/allVoucher/manageRecipientsTableForVoucher/ManageRecipientsTable.vue";
import AppSnackBar from "../../../common/AppSnackBar/AppSnackBar.vue";
import AppLoader from "../../../common/AppLoader/AppLoader";
import Breadcrumb from "../../../common/AppBreadCrumbs/BreadCrumbs.vue";
import { DropDownButton } from "@progress/kendo-vue-buttons";
import { getSingleVOucherCampaign } from "@/services/clubDetails/voucher.service";
import SelectAnAudienceListModal from "@/components/common/selectAnAudienceListModal/SelectAnAudienceListModal.vue";

import AddUnassignedVoucherModal from "./addUnasignedVoucheModal/AddUnasignedVoucheModal";

export default {
  components: {
    buttongroup: ButtonGroup,
    kbutton: Button,
    SelectMemberModal: SelectMemberModal,
    ManageRecipientsTable,
    AppSnackBar,
    "app-loader": AppLoader,
    Breadcrumb,
    DropDownButton,
    SelectAnAudienceListModal,
    AddUnassignedVoucherModal,
  },
  data() {
    return {
      itemCode: "allVoucher",
      moduleCode: "voucher",
      itemTagTypes: {
        1: "dpAudience",
        2: "ecAudience",
        3: "vAudience",
      },
      moduleTagTypes: {
        1: "digitalPass",
        2: "emailCampaign",
        3: "voucher",
      },
      childItemCodeType: {
        1: "dpAllAudience",
        2: "ecAllAudience",
        3: "vAllAudience",
      },
      popupSettings: {
        anchorAlign: {
          horizontal: "right",
          vertical: "bottom",
        },
        popupAlign: {
          horizontal: "right",
          vertical: "top",
        },
        popupClass: "popup-design",
      },
      vouchername: "",
      voucherPrefix: "",
      voucherInfo: {},
      isLoading: true,
      modalVisible: false,
      isSelectModalOpen: false,
      documentId: 0,
      selectedMembers: [],
      clubId: this.$route.params.id,
      voucherCampaignId: +this.$route.query.voucherCampaignId,
      voucherType: "",
      addAudienceDropDown: [{ id: 0, text: "Add Audience" }],
      payloadForAddVoucher: {},
    };
  },
  mounted() {
    if (!this.hasGetAccess()) {
      this.$store.commit("SHOW_NOTIFICATION", {
        message: "You do not have access to view campaigns.",
        type: "warning",
      });
      this.$router.push(`/club-details/${this.$route.params.id}/dashboard`);
    }
    this.getRecipient();
    this.getVoucherInfo();
  },
  unmounted() {
    this.$store.commit("SET_RECIPIENTS", []);
  },
  computed: {
    getVoucherName() {
      return this.vouchername;
    },
    membersData() {
      if (this.$store.state.voucherManageRecipients.setRecipients.items) {
        this.isLoading = false;
      }
      return this.$store.state.voucherManageRecipients.setRecipients;
    },
    isSearchingOrFilterig() {
      return (
        this.$store.state.voucherManageRecipients.searchQuery ||
        this.$store.state.voucherManageRecipients.voucherStatus ||
        this.$store.state.voucherManageRecipients.emailStatus ||
        this.$store.state.voucherManageRecipients.advanceSearchQuery
      );
    },
    isShowMsg() {
      return this.$store.state.voucherManageRecipients.showMsg;
    },
    showMsg() {
      return this.$store.state.voucherManageRecipients.status;
    },
    recipientsListLoading() {
      return this.$store.state.voucherManageRecipients.loading;
    },
    addVoucherLoading() {
      return this.$store.state.voucherManageRecipients.addVoucherLoading;
    },
    isSearching() {
      return this.$store.state.voucherManageRecipients.searchQuery;
    },
    isModalVisible() {
      return this.$store.state.common.modalVisible;
    },
    isAddVoucherModalVisible() {
      return this.$store.state.voucherManageRecipients
        .addVoucherModalVisibility;
    },
  },
  methods: {
    hasGetAccess() {
      return this.$hasAccessToModule({
        permissionKey: "hasGetPermission",
        moduleCode: this.moduleCode,
        itemCode: this.itemCode,
        childItemCode: null,
      });
    },
    hasDeleteAccess() {
      return this.$hasAccessToModule({
        permissionKey: "hasDeletePermission",
        moduleCode: this.moduleCode,
        itemCode: this.itemCode,
        childItemCode: null,
      });
    },
    hasEditAccess() {
      return this.$hasAccessToModule({
        permissionKey: "hasPutPermission",
        moduleCode: this.moduleCode,
        itemCode: this.itemCode,
        childItemCode: null,
      });
    },
    hasCreateAccess() {
      return this.$hasAccessToModule({
        permissionKey: "hasPostPermission",
        moduleCode: this.moduleCode,
        itemCode: this.itemCode,
        childItemCode: null,
      });
    },
    hasAudienceGetAccess() {
      return this.$hasAccessToModule({
        permissionKey: "hasGetPermission",
        moduleCode: this.moduleTagTypes[+this.$route.query.type],
        itemCode: this.itemTagTypes[+this.$route.query.type],
        childItemCode: this.childItemCodeType[+this.$route.query.type],
      });
    },
    hasAudienceDeleteAccess() {
      return this.$hasAccessToModule({
        permissionKey: "hasDeletePermission",
        moduleCode: this.moduleTagTypes[+this.$route.query.type],
        itemCode: this.itemTagTypes[+this.$route.query.type],
        childItemCode: this.childItemCodeType[+this.$route.query.type],
      });
    },
    hasAudienceEditAccess() {
      return this.$hasAccessToModule({
        permissionKey: "hasPutPermission",
        moduleCode: this.moduleTagTypes[+this.$route.query.type],
        itemCode: this.itemTagTypes[+this.$route.query.type],
        childItemCode: this.childItemCodeType[+this.$route.query.type],
      });
    },
    hasAudienceCreateAccess() {
      return this.$hasAccessToModule({
        permissionKey: "hasPostPermission",
        moduleCode: this.moduleTagTypes[+this.$route.query.type],
        itemCode: this.itemTagTypes[+this.$route.query.type],
        childItemCode: this.childItemCodeType[+this.$route.query.type],
      });
    },
    openModalSelect() {
      this.$store.commit("SET_ALL_RECIPIENT_MODAL_OPEN", true);
    },
    onItemClick() {
      this.openModal();
    },
    openModal() {
      this.modalVisible = true;
      this.isSelectModalOpen = true;
    },
    openModalForAddAudienceList() {
      this.$store.commit("MODAL_VISIBLE_COMMON", true);
    },
    openModalForAddAVoucher() {
      this.$store.commit("TOGGLE_ADD_VOUCHER_MODAL_VISIBILITY", true);
    },
    openAddUnassignedVoucherModal() {
      this.$store.commit("MODAL_VISIBLE_COMMON", true);
    },
    openUploadDataModal() {
      this.isSelectModalOpen = false;
    },
    closeSelectMemberModal() {
      this.isSelectModalOpen = false;
    },
    closeUploadDataModal() {
      this.isSelectModalOpen = true;
    },
    navigateToVoucherHomepage() {
      this.$router.push({
        name: "AllVoucher",
        query: {
          type: "3",
        },
      });
    },
    navigateToEditVoucher() {
      this.$router.push({
        name: "EditVoucherCampaign",
        query: {
          voucher_id: this.voucherCampaignId,
          club_name: this.$route.query.club_name,
          type: +this.$route.query.type,
          voucher_type: this.voucherType,
        },
        params: {
          fromManageRecipients: true,
          voucherCampaignId: this.voucherCampaignId,
        },
      });
    },
    selectedMembersList(members) {
      this.selectedMembers = members;
    },
    getVoucherInfo() {
      // this.isLoading = true;
      let payload = {
        voucherCampaignId: this.voucherCampaignId,
        clubId: this.clubId,
      };

      getSingleVOucherCampaign(payload)
        .then((response) => {
          // this.isLoading = false;
          this.voucherInfo = {
            ...this.voucherInfo,
            ...response?.data?.data,
          };
          this.vouchername = this.voucherInfo.name;
          this.voucherPrefix = this.voucherInfo.voucherPrefix;
          this.voucherType = this.voucherInfo.voucherType;
          this.payloadForAddVoucher = {
            clubId: this.clubId,
            voucherCampaignId: this.voucherCampaignId,
            amount: this.voucherInfo.voucherAmount,
            expiryDate: this.voucherInfo.expiryDate,
          };
        })
        .catch((error) => {
          // this.isLoading = false;
          this.loader = false;
        });
    },
    getRecipient() {
      let payload = {
        voucherCampaignId: this.voucherCampaignId,
        clubId: this.clubId,
        offset: 0,
        limit: 20,
      };
      this.$store.dispatch("getVoucherRecipients", payload);
    },
  },
};
</script>

<style></style>
