<template src="./ManageRecipientsTable.html"></template>
<style lang="scss">
@import "./ManageRecipientsTable.scss";
</style>

<script>
import { Grid, GridToolbar, GridNoRecords } from "@progress/kendo-vue-grid";
import { Input, RadioButton } from "@progress/kendo-vue-inputs";
import { Button, ButtonGroup } from "@progress/kendo-vue-buttons";
import { DropDownList } from "@progress/kendo-vue-dropdowns";
import UploadDataForVoucherModal from "./uploadDataForVoucherModal/UploadDataForVoucherModal.vue";
import MapDataToVoucherFieldsModal from "./mapDataToVoucherFieldsModal/MapDataToVoucherFieldsModal.vue";
import SelectMemberModal from "../../digitalPass/selectMemberModal/SelectMemberModal.vue";
import RecipientsMemberDetails from "../../digitalPass/recipientsMemberDetails/RecipientsMemberDetails";
import DataUnavailableUserModal from "./dataUnavailableUserModal/DataUnavailableUserModal.vue";
import ManageRecipientsDeleteModal from "../../digitalPass/manageRecipientsTable/manageRecipientsDeleteModal/ManageRecipientsDeleteModal.vue";
import SnackBarModel from "@/models/snackBar/snackBarModel";
import { DropDownButton } from "@progress/kendo-vue-buttons";
import AppPopUp from "../../../common/AppPopUp/AppPopUp.vue";
import SelectAnAudienceListModal from "@/components/common/selectAnAudienceListModal/SelectAnAudienceListModal.vue";
import { Popup } from "@progress/kendo-vue-popup";
import { DateTimePicker } from "@progress/kendo-vue-dateinputs";
import { DatePicker } from "@progress/kendo-vue-dateinputs";
import moment from "moment";
import AppSnackBar from "../../../common/AppSnackBar/AppSnackBar.vue";
import {
  voucherStatusUpdateService,
  sendVoucherEmailService,
  updateRefillAmountService,
  updateExpiryDateService,
  updateStatusService,
  deleteVoucherRecipientsService,
} from "../../../../services/clubDetails/voucherManageRecipients.service";
import AppLoader from "@/components/common/AppLoader/AppLoader.vue";
import { NumericTextBox } from "@progress/kendo-vue-inputs";
import { RadioGroup } from "@progress/kendo-vue-inputs";
import SelectRecipientFromVoucherAudienceModalVue from "../selectRecipientFromVoucherAudienceModal/selectRecipientFromVoucherAudienceModal.vue";
import ManageRecipientUnAssignedDeleteModal from "./manageRecipientUnAssignedDeleteModal/ManageRecipientUnAssignedDeleteModal.vue";
import { Checkbox } from "@progress/kendo-vue-inputs";
import GeneratingVoucherCodeSnackbar from "./generatingVoucherCodeSnackbar/GeneratingVoucherCodeSnackbar.vue";
import GeneratingVoucherStatusSnackbar from "./generatingVoucherStatusSnackbar/generatingVoucherStatusSnackbar.vue";
import RefillVoucherAmountModal from "./refillVoucherAmountModal/RefillVoucherAmountModal.vue";
import ChangeExpiryDateModal from "./changeExpiryDateModal/ChangeExpiryDateModal.vue";
import ChangeStatusModal from "./changeStatusModal/changeStatusModal.vue";
import BulkActionSnacbar from "./bulkActionSnacbar/BulkActionSnacbar.vue";
import { GetFormattedDate } from "@/utils/helper";
import vClickOutside from "click-outside-vue3";

export default {
  name: "ManageRecipientsTable",
  directives: {
    clickOutside: vClickOutside.directive,
  },
  props: {
    title: {
      type: String,
      default: "No Title",
    },
    templateType: {
      type: Object,
    },
    isBreadCrumb: {
      type: Boolean,
      default: true,
    },
    voucherName: {
      type: String,
      default: "",
    },
  },
  components: {
    buttongroup: ButtonGroup,
    radioButton: RadioButton,
    GridNoRecords,
    Grid: Grid,
    toolbar: GridToolbar,
    "k-input": Input,
    "app-loader": AppLoader,
    kbutton: Button,
    dropdownlist: DropDownList,
    UploadDataForVoucherModal,
    MapDataToVoucherFieldsModal,
    SelectMemberModal,
    RecipientsMemberDetails,
    DataUnavailableUserModal,
    ManageRecipientsDeleteModal,
    DropDownButton,
    AppPopUp,
    SelectAnAudienceListModal,
    Popup: Popup,
    datetimepicker: DateTimePicker,
    datePicker: DatePicker,
    AppSnackBar,
    NumericTextBox,
    radiogroup: RadioGroup,
    SelectRecipientFromVoucherAudienceModalVue,
    ManageRecipientUnAssignedDeleteModal,
    checkbox: Checkbox,
    GeneratingVoucherCodeSnackbar,
    GeneratingVoucherStatusSnackbar,
    RefillVoucherAmountModal,
    ChangeExpiryDateModal,
    BulkActionSnacbar,
    ChangeStatusModal,
  },

  data() {
    return {
      itemCode: "allVoucher",
      moduleCode: "voucher",
      itemTagTypes: {
        1: "dpAudience",
        2: "ecAudience",
        3: "vAudience",
      },
      moduleTagTypes: {
        1: "digitalPass",
        2: "emailCampaign",
        3: "voucher",
      },
      childItemCodeType: {
        1: "dpAllAudience",
        2: "ecAllAudience",
        3: "vAllAudience",
      },
      sortBy: "",
      sortDir: "",

      sort: [{ field: "email", dir: "asc" }],
      deleteUnassignedRecipientPayload: {},
      dataItems: [
        {
          label: "On",
          value: 1,
        },
        {
          label: "Before",
          value: 2,
        },
        {
          label: "After",
          value: 3,
        },
        {
          label: "Between",
          value: 4,
        },
      ],
      moreActionOptions: [
        { id: 1, text: "Refill Amount" },
        { id: 2, text: "Change Expiry Date" },
        { id: 3, text: "Export" },
        // { id: 4, text: "Delete" },
        //  { id: 5, text: "Change Status" },
      ],
      popupSettings: {
        anchorAlign: {
          horizontal: "right",
          vertical: "bottom",
        },
        popupAlign: {
          horizontal: "right",
          vertical: "top",
        },
        popupClass: "popup-design",
      },
      popupSettings120: {
        anchorAlign: {
          horizontal: "right",
          vertical: "bottom",
        },
        popupAlign: {
          horizontal: "right",
          vertical: "top",
        },
        popupClass: "popup-design",
      },

      isLoading: false,
      isAdvanceFilteringExist: false,
      advanceFilterPayload: {},
      voucherCheckBoxStatus: false,
      clearAdvanceFilterFields: false,

      clubId: this.$route.params.id,
      noVoucherIdRecipients: [],
      voucherCampaignId: this.$route.query.voucherCampaignId,
      searchWord: "",
      selectedField: "selected",
      isUploadModalOpen: false,
      isMapDataModalOpen: false,
      isViewDetailsModalOpen: false,
      isCkUserDataAvailableModalOpen: false,
      addFromClubmodalVisible: false,
      addFromClubIsSelectModalOpen: false,
      addFromClubisUploadModalOpen: false,
      addFromClubisMapDataModalOpen: false,
      gridPageable: {
        buttonCount: 5,
        info: true,
        type: "numeric",
        pageSizes: [10, 20, 50, 100],
        previousNext: true,
      },

      emailStatusArray: [
        "Not Sent",
        "Sending",
        "Sent",
        "Opened",
        "Clicked",
        "Delivered",
        "Not Delivered",
      ],

      moreOptionDropdownitems: [
        // {
        //   text: "Import Data For Voucher",
        //   dropId: 1,
        // },
        {
          text: "Export Audience",
          dropId: 2,
        },
        {
          text: "Report",
          dropId: 3,
        },
      ],
      voucherOptionsForUnassigned: [
        {
          text: "Assign",
          dropId: 1,
        },
        // {
        //   text: "Delete",
        //   dropId: 2,
        // },
        {
          text: "Refill Amount",
          dropId: 3,
        },
        {
          text: "Change Expiry Date",
          dropId: 4,
        },
      ],
      voucherOptionsForAssignedAndActive: [
        {
          text: "Refill Amount",
          dropId: 3,
        },
        {
          text: "Change Expiry Date",
          dropId: 4,
        },
      ],
      voucherOptionsForAssigned: [
        // {
        //   text: "Delete",
        //   dropId: 2,
        // },
        {
          text: "Refill Amount",
          dropId: 3,
        },
        {
          text: "Change Expiry Date",
          dropId: 4,
        },
      ],
      voucherExpiredOptionsForAssigned: [
        {
          text: "Change Expiry Date",
          dropId: 4,
        },
      ],

      selectedItems: [],
      copyOfSelectedItems: [],
      documentIdPass: "",
      voucherStatusesValues: [
        { id: 0, text: "All" },
        { id: 3, text: "Active" },
        { id: 4, text: "Inactive" },
        { id: 1, text: "Suspend" },
        { id: 2, text: "Redeemed" },
        { id: 5, text: "Expired" },
      ],

      voucherStatusValue: { id: 0, text: "All" },
      addAudienceDropDown: [{ id: 0, text: "Add Audience" }],
      emailStatuses: [
        { id: 1, text: "All", value: "All" },
        { id: 2, text: "Sent", value: "Sent" },
        { id: 3, text: "Not Sent", value: "NotSent" },
        { id: 4, text: "Delivered", value: "Delivered" },
        { id: 5, text: "Not Delivered", value: "NotDelivered" },
        { id: 6, text: "Opened", value: "Opened" },
        { id: 7, text: "Clicked", value: "Clicked" },
      ],
      emailStatusValue: { id: 1, text: "All", value: "All" },
      voucherStatuses: [{ id: 0, text: "Delete" }],
      selectedMembers: [],
      selectedMemberList: [],
      selectedListWithVoucherId: [],
      timer: null,
      advanceFilterShow: false,
      usedAmountStartRange: 0,
      usedAmountEndRange: 100,
      voucherCode: "",
      createdOnStartDate: "",
      createdOnEndDate: "",
      deleteModalVisiable: false,
      notifyMessage: "",
      notifyType: "",
      isNotify: false,
      amountType: [
        { id: 1, text: "=", value: 1 },
        { id: 2, text: ">=", value: 2 },
        { id: 3, text: "<=", value: 3 },
        { id: 4, text: ">", value: 4 },
        { id: 5, text: "<", value: 5 },
      ],
      amountTypeValue: { id: 1, text: "=", value: 1 },
      redeemedAmount: "",
      DateFilterStartDate: "",
      DateFilterEndDate: "",
      redeemedDate: "",
      DateFilterType: 1,
      payload_for_assign_audience_to_recipient: {},
      showUnassignedVouchersOnly: false,

      refillAmountModalVisibility: false,
      updateRefillAmountLoading: false,
      changeExpiryDateModalVisibility: false,
      changeStatusModalVisibility: false,
      updateExpiryDateLoading: false,

      voucherRecipientIdsForRefillAmount: [],
      voucherRecipientIdsForChangeExpiryDate: [],
      voucherRecipientIdsForChangeStatus: [],

      refillBulkActionSnackbarVisibility: false,
      refillSnackBarModalDetails: {
        title: "Amount Refill Status",
        body: "Performing bulk action for refilling your selected vouchers. This may take a while. You can come back later or reload this page to see the updates.",
        isWithoutCount: true,
      },

      changeExpiryDateBulkActionSnackbarVisibility: false,
      changeExpiryDateSnackBarModalDetails: {
        title: "Expiry Date Update Status",
        body: "Performing bulk action for updating expiry date of your selected vouchers. This may take a while. You can come back later or reload this page to see the updates.",
        isWithoutCount: true,
      },

      bulkDeleteSnackbarVisibility: false,
      bulkDeleteSnackBarModalDetails: {
        isWithoutCount: false,
        title: "Voucher Delete Status",
        successFirstMessage: "Deleted",
        successSecondMessage: "",
        errorFirstMessage: " Failed to delete ",
        errorSecondMessage:
          "as they are currently in use or used by your voucher recipients.",
      },

      deleteRecipientModalVisibility: false,
      voucherIdForDeleteRecipient: "",

      statusChangeSuccessStatus: false,
    };
  },
  computed: {
    recipientsListLoading() {
      return this.$store.state.voucherManageRecipients.loading;
    },
    addVoucherLoading() {
      return this.$store.state.voucherManageRecipients.addVoucherLoading;
    },
    areAllSelected() {
      return (
        this.selectedItems?.length === this.membersData.items?.length &&
        this.membersData.items?.length != 0
      );
    },

    generateCodeButtonVisibility() {
      let visible = true;
      // ;
      this.selectedListWithVoucherId.map((item) => {
        if (item.hasVoucherCode || item.processingStatus == 1) {
          visible = false;
        }
      });
      return visible;
    },

    sendEmailButtonVisibility() {
      let visible = true;
      // ;
      this.selectedListWithVoucherId.map((item) => {
        // if (item?.emailStatus != 0 || item?.isUnassigned) {
        if (
          item?.isUnassigned ||
          item?.voucherCode === "Generating..." ||
          item?.voucherStatus == 2 ||
          [0, 2, 3].includes(item?.subscriberStatus)
        ) {
          visible = false;
        }
      });
      return visible;
    },

    isModalVisible() {
      return this.$store.state.common.modalVisible;
    },

    columns() {
      return [
        {
          field: "selected",
          headerSelectionValue: this.areAllSelected,
        },
        {
          field: "email",
          title: "Email",
          cell: "emailCell",
        },
        {
          field: "name",
          title: "Name",
          cell: "nameCell",
        },
        {
          field: "voucherCode",
          title: "Voucher Code",
          // cell: "voucherCodeCell",
        },
        {
          field: "voucherAmount",
          title: "Amount",
          // cell: "voucherAmountCell",
        },
        {
          field: "usedAmount",
          title: "Used",
          // cell: "usedAmountCell",
        },
        {
          field: "remainingAmount",
          title: "Remaining",
          // cell: "remainingAmountCell",
        },
        {
          field: "expiryDateTime",
          title: "Expiry Date",
          // cell: "expiryDateCell",
        },
        {
          field: "emailStatus",
          title: "Email Status",
          cell: "emailStatusCell",
        },
        {
          field: "voucherStatus",
          title: "Voucher Status",
          cell: "voucherStatusCell",
        },
      ];
    },
    membersData() {
      return this.$store.state.manageRecipients.setRecipients;
    },
    total() {
      return this.membersData ? this.membersData.size : 0;
    },
    take() {
      return this.membersData ? this.membersData.limit : 0;
    },
    skip() {
      this.selectedItems = [];
      return this.membersData ? this.membersData.offset : 0;
    },
    isDeleteOpen() {
      return this.$store.state.manageRecipients.visible;
    },
    isSearching() {
      return this.$store.state.members.searchQuery;
    },
    showSuccessMsg() {
      return this.$store.state.voucherManageRecipients.successStatus;
    },
    showErrorMsg() {
      return this.$store.state.voucherManageRecipients.errorStatus;
    },
    isShowSuccessMsg() {
      return this.$store.state.voucherManageRecipients.showSuccessMsg;
    },
    isShowErrorMsg() {
      return this.$store.state.voucherManageRecipients.showErrorMsg;
    },
    unassigned_voucher_delete_modal_visibility() {
      return this.$store.state.voucherManageRecipients.deleteModalVisibility;
    },
    unassigned_voucher_assign_modal_visibility() {
      return this.$store.state.allAudience.selectRecipientModal;
    },
    assignAudienceLoading() {
      return this.$store.state.voucherManageRecipients.assignAudienceLoading;
    },
    generate_voucher_code_snackbar_visibility() {
      return this.$store.state.voucherManageRecipients
        .generatingVoucherCodeSnackbarVisibility;
    },
    generate_voucher_status_snackbar_visibility() {
      return this.$store.state.voucherManageRecipients
        .generatingVoucherStatusSnackbarVisibility;
    },
  },
  methods: {
    hasGetAccess() {
      return this.$hasAccessToModule({
        permissionKey: "hasGetPermission",
        moduleCode: this.moduleCode,
        itemCode: this.itemCode,
        childItemCode: null,
      });
    },
    hasDeleteAccess() {
      return this.$hasAccessToModule({
        permissionKey: "hasDeletePermission",
        moduleCode: this.moduleCode,
        itemCode: this.itemCode,
        childItemCode: null,
      });
    },
    hasEditAccess() {
      return this.$hasAccessToModule({
        permissionKey: "hasPutPermission",
        moduleCode: this.moduleCode,
        itemCode: this.itemCode,
        childItemCode: null,
      });
    },
    hasCreateAccess() {
      return this.$hasAccessToModule({
        permissionKey: "hasPostPermission",
        moduleCode: this.moduleCode,
        itemCode: this.itemCode,
        childItemCode: null,
      });
    },
    hasAudienceGetAccess() {
      return this.$hasAccessToModule({
        permissionKey: "hasGetPermission",
        moduleCode: this.moduleTagTypes[+this.$route.query.type],
        itemCode: this.itemTagTypes[+this.$route.query.type],
        childItemCode: this.childItemCodeType[+this.$route.query.type],
      });
    },
    hasAudienceDeleteAccess() {
      return this.$hasAccessToModule({
        permissionKey: "hasDeletePermission",
        moduleCode: this.moduleTagTypes[+this.$route.query.type],
        itemCode: this.itemTagTypes[+this.$route.query.type],
        childItemCode: this.childItemCodeType[+this.$route.query.type],
      });
    },
    hasAudienceEditAccess() {
      return this.$hasAccessToModule({
        permissionKey: "hasPutPermission",
        moduleCode: this.moduleTagTypes[+this.$route.query.type],
        itemCode: this.itemTagTypes[+this.$route.query.type],
        childItemCode: this.childItemCodeType[+this.$route.query.type],
      });
    },
    hasAudienceCreateAccess() {
      return this.$hasAccessToModule({
        permissionKey: "hasPostPermission",
        moduleCode: this.moduleTagTypes[+this.$route.query.type],
        itemCode: this.itemTagTypes[+this.$route.query.type],
        childItemCode: this.childItemCodeType[+this.$route.query.type],
      });
    },
    callGetRecipients() {
      let payload = {
        clubId: +this.clubId,
        limit: this.take,
        offset: this.skip,
        voucherCampaignId: this.voucherCampaignId,
        searchText: this.searchWord,
        voucherEmailStatus: this.emailStatusValue?.value,
        voucherStatus:
          this.voucherStatusValue.text == "Suspend"
            ? "Suspended"
            : this.voucherStatusValue?.text,
      };
      if (this.isAdvanceFilteringExist) {
        payload = {
          ...payload,
          ...this.advanceFilterPayload,
        };
      }
      this.$store.dispatch("getVoucherRecipients", payload);
    },

    onCancelRedillSnackbar() {
      this.refillBulkActionSnackbarVisibility = false;
    },

    onCancelChangeExpiryDateSnackbar() {
      this.changeExpiryDateBulkActionSnackbarVisibility = false;
    },

    onCancelBulkDeleteSnackbar() {
      this.bulkDeleteSnackbarVisibility = false;
    },

    onCancelDeleteRecipientModal() {
      this.deleteRecipientModalVisibility = false;
      this.voucherIdForDeleteRecipient = "";
    },

    onConfirmDeleteRecipientModal() {
      this.deleteRecipientModalVisibility = false;

      const payload = {
        clubId: this.$route.params.id,
        ids: this.voucherIdForDeleteRecipient
          ? [this.voucherIdForDeleteRecipient]
          : this.selectedItems,
        voucherCampaignId: this.$route.query.voucherCampaignId,
      };

      this.isLoading = true;

      deleteVoucherRecipientsService(payload)
        .then((res) => {
          this.selectedItems = [];
          this.selectedListWithVoucherId = [];
          this.isLoading = false;
          // ;
          if (this.voucherIdForDeleteRecipient) {
            this.voucherIdForDeleteRecipient = null;
          }
          this.bulkDeleteSnackbarVisibility = true;
          this.bulkDeleteSnackBarModalDetails = {
            ...this.bulkDeleteSnackBarModalDetails,
            successCount: res?.data?.data?.successCount,
            errorCount: res?.data?.data?.errorCount,
          };
          this.callGetRecipients();
        })
        .catch((err) => {
          if (this.voucherIdForDeleteRecipient) {
            this.voucherIdForDeleteRecipient = null;
          }
          this.isLoading = false;
          // ;
          this.showNotification(
            "Something went wrong while deleting recipients.",
            "error"
          );
        });
    },

    actionVerification() {
      if (this.selectedItems.length == 1) {
        if (this?.selectedListWithVoucherId?.[0]?.voucherStatus == 2) {
          this.showNotification(
            "Couldn't take action because the voucher is suspended.",
            "warning"
          );
          return false;
        }

        if (this?.selectedListWithVoucherId?.[0]?.voucherStatus == 3) {
          this.showNotification(
            "Couldn't take action because the voucher is redeemed.",
            "warning"
          );
          return false;
        }
        return true;
      }
      return true;
    },

    moreActionsHandler(e, id) {
      // ;

      if (e?.item?.id === 1) {
        if (this.actionVerification()) {
          this.onBulkRefill();
        }
      }
      if (e?.item?.id === 2) {
        if (this.actionVerification()) {
          this.onBulkChangeExpiryDate();
        }
      }
      if (e?.item?.id === 3) {
        this.selectedExport();
      }
      if (e?.item?.id === 4) {
        this.deleteRecipientModalVisibility = true;
      }
      if (e?.item?.id === 5) {
        this.changeStatusModalVisibility = true;
        this.onBulkChangStatus();
      }
    },
    sortChangeHandler: function (e) {
      // The idea behind using the following setTimeout method is to
      // demonstrate how we can show a loader while fetching data.
      // In a real-life scenarios with remote data binding, the 'loader'
      // property should be updated before making a server request
      // and after the request completes.
      this.sort = e.sort;

      if (e.sort.length > 0) {
        this.sortBy = e.sort[0].field;
        this.sortDir = e.sort[0].dir;
      } else {
        this.sortBy = "";
        this.sortDir = "";
      }

      // ;

      let payload = {
        clubId: +this.clubId,
        limit: this.take,
        offset: this.skip,
        voucherCampaignId: this.voucherCampaignId,
        searchText: this.searchWord,
        voucherEmailStatus: this.emailStatusValue?.value,
        voucherStatus:
          this.voucherStatusValue.text == "Suspend"
            ? "Suspended"
            : this.voucherStatusValue?.text,
      };

      if (this.sortBy && this.sortDir) {
        payload.orderBy = `${this.sortBy} ${this.sortDir}`;
      }

      if (this.isAdvanceFilteringExist) {
        payload = {
          ...payload,
          ...this.advanceFilterPayload,
        };
      }

      this.$store.dispatch("getVoucherRecipients", payload);
    },
    handleVoucherCheckBoxStatus(event) {
      this.showUnassignedVouchersOnly = event.value;
    },
    handleChange(e) {
      this.DateFilterType = e.value;
    },
    getDropId(e) {
      if (e == 1) {
        this.openModal();
      }
      if (e == 2) {
        this.exportVoucherAudience();
      }
      if (e == 3) {
        this.navigateToReportPage();
      }
    },
    getDropIdForVoucherOption(e, voucher) {
      // ;

      let payload = {
        clubId: +this.clubId,
        voucherRecipientId: voucher.id,
        limit: this.take,
        offset: this.skip,
        voucherCampaignId: this.voucherCampaignId,
        searchText: this.searchWord,
        voucherEmailStatus: this.emailStatusValue?.value,
        voucherStatus:
          this.voucherStatusValue.text == "Suspend"
            ? "Suspended"
            : this.voucherStatusValue?.text,
      };

      if (this.isAdvanceFilteringExist) {
        payload = {
          ...payload,
          ...this.advanceFilterPayload,
        };
      }

      if (e == 1) {
        this.payload_for_assign_audience_to_recipient = payload;
        setTimeout(() => {
          this.$store.commit("SET_ALL_RECIPIENT_MODAL_OPEN", true);
        }, 300);
      }
      if (e === 2) {
        this.voucherIdForDeleteRecipient = voucher.id;
        this.deleteRecipientModalVisibility = true;
      }

      if (e === 3) {
        this.voucherRecipientIdsForRefillAmount = [voucher.id];
        this.refillAmountModalVisibility = true;
      }

      if (e === 4) {
        // ;
        this.voucherRecipientIdsForChangeExpiryDate = [voucher.id];
        this.changeExpiryDateModalVisibility = true;
      }
      if (e === 5) {
        // ;
        this.voucherRecipientIdsForChangeStatus = [voucher.id];
        this.changeStatusModalVisibility = true;
      }
    },

    onCancelExpiryDateChangeModal() {
      this.changeExpiryDateModalVisibility = false;
    },

    onCancelStatusChangeModal() {
      this.changeStatusModalVisibility = false;
    },

    onConfirmExpiryDateChangeModal(model) {
      // ;
      this.changeExpiryDateModalVisibility = false;

      const payload = {
        voucherCampaignId: this.voucherCampaignId,
        voucherRecipientIds: this.voucherRecipientIdsForChangeExpiryDate,
        expiryDate: model.expiryDate,
        clubId: this.clubId,
      };

      this.updateExpiryDateLoading = true;

      updateExpiryDateService(payload)
        .then((res) => {
          this.selectedItems = [];
          this.selectedListWithVoucherId = [];
          this.updateExpiryDateLoading = false;
          // ;
          if (this.voucherRecipientIdsForChangeExpiryDate.length == 1) {
            this.showNotification(
              "Expiry date updated successfully.",
              "success"
            );
          } else {
            this.changeExpiryDateBulkActionSnackbarVisibility = true;
          }
          // this.changeExpiryDateSnackBarModalDetails = {
          //   ...this.changeExpiryDateSnackBarModalDetails,
          //   successCount: res?.data?.data?.successCount,
          //   errorCount: res?.data?.data?.errorCount,
          //   successFirstMessage: `Changed expiry date to ${GetFormattedDate(
          //     model.expiryDate
          //   )} for`,
          // };
          this.callGetRecipients();
        })
        .catch((err) => {
          this.updateExpiryDateLoading = false;
          this.showNotification(
            "Something went wrong while updating expiry date.",
            "error"
          );
        });
    },

    onConfirmStatusChangeModal(model) {
      // ;
      this.changeStatusModalVisibility = false;

      const payload = {
        clubId: this.clubId,
        voucherCampaignRecipientIds: this.voucherRecipientIdsForChangeStatus,
        voucherStatus: model.status.text,
      };
      this.updateExpiryDateLoading = true;

      updateStatusService(payload)
        .then((res) => {
          this.selectedItems = [];
          this.selectedListWithVoucherId = [];
          this.updateExpiryDateLoading = false;
          // ;
          if (res?.data?.status?.code == 200) {
            // this.showNotification(
            //   "Status updated successfully.",
            //   "success"
            // );
            // this.statusChangeSuccessStatus= true;
            this.$store.commit(
              "SET_GENERATING_VOUCHER_STATUS_SNACKBAR_VISIBILITY",
              true
            );
          } else {
            this.changeStatusModalVisibility = true;
          }
          // this.changeExpiryDateSnackBarModalDetails = {
          //   ...this.changeExpiryDateSnackBarModalDetails,
          //   successCount: res?.data?.data?.successCount,
          //   errorCount: res?.data?.data?.errorCount,
          //   successFirstMessage: `Changed expiry date to ${GetFormattedDate(
          //     model.expiryDate
          //   )} for`,
          // };
          this.callGetRecipients();
        })
        .catch((err) => {
          this.updateExpiryDateLoading = false;
          this.showNotification(
            "Something went wrong while updating status.",
            "error"
          );
        });
    },

    onCancelRefillAmountModal() {
      // ;
      this.refillAmountModalVisibility = false;
    },

    onConfirmRefillAmountModal(model) {
      // ;
      this.refillAmountModalVisibility = false;

      const payload = {
        voucherCampaignId: this.voucherCampaignId,
        voucherRecipientIds: this.voucherRecipientIdsForRefillAmount,
        refillAmount: model.refillAmount,
        clubId: this.clubId,
      };

      this.updateRefillAmountLoading = true;

      updateRefillAmountService(payload)
        .then((res) => {
          this.selectedItems = [];
          this.selectedListWithVoucherId = [];
          this.updateRefillAmountLoading = false;
          if (this.voucherRecipientIdsForRefillAmount.length == 1) {
            this.showNotification("Amount refilled successfully.", "success");
          } else {
            this.refillBulkActionSnackbarVisibility = true;
          }
          // this.refillSnackBarModalDetails = {
          //   ...this.refillSnackBarModalDetails,
          //   successCount: res?.data?.data?.successCount,
          //   errorCount: res?.data?.data?.errorCount,
          //   successFirstMessage: `Refilled amount $${model?.refillAmount?.toFixed(
          //     2
          //   )} for`,
          // };

          this.callGetRecipients();
        })
        .catch((err) => {
          this.updateRefillAmountLoading = false;

          this.showNotification(
            "Something went wrong while updating refill amount.",
            "error"
          );
        });
    },

    navigateToReportPage() {
      this.$router.push({
        name: "VoucherReport",
        query: {
          voucher_campaign_id: this.voucherCampaignId,
          club_name: this.$route.query.club_name,
          type: +this.$route.query.type,
          campaign_name: this.voucherName,
        },
        params: {
          voucherCampaignId: this.voucherCampaignId,
        },
      });
    },

    updateCreatedOnStartDate(event) {
      this.createdOnStartDate = event.target.value;
    },

    updateCreatedOnEndDate(event) {
      this.createdOnEndDate = event.target.value;
    },

    onVoucherStatusItemClick(e, id) {
      if (e.item.text == "Suspend") e.item.text == "Suspended";
      this.isLoading = true;
      let payload = {
        clubId: this.clubId,
        voucherCampaignId: this.voucherCampaignId,
        voucherCampaignRecipientId: id,
        voucherStatus: e.item.text,
      };

      voucherStatusUpdateService(payload)
        .then(() => {
          this.isLoading = false;
          this.callGetRecipients();

          if (payload.voucherStatus == "Active") {
            this.showNotification(
              "Selected voucher has been activated and is ready to use.",
              "success"
            );
          }

          if (payload.voucherStatus == "Suspended") {
            this.showNotification(
              "Selected voucher has been suspended and disabled for use.",
              "success"
            );
          }
        })
        .catch((error) => {
          this.isLoading = false;
          this.showNotification(
            `${
              error?.response?.data?.errors?.VoucherStatus?.[0] ||
              "Something went wrong while updating voucher status."
            }`,
            "error"
          );
        });
    },

    onOpen(status) {
      this.voucherStatuses.splice(0, 1);

      if (status === 0) {
        this.voucherStatuses.push({ id: 0, text: "Active" });
      }
      if (status === 1) {
        this.voucherStatuses.push({ id: 0, text: "Suspended" });
      }
      if (status === 2) {
        this.voucherStatuses.push({ id: 0, text: "Active" });
      }
    },
    getDate(date) {
      if (date != null) {
        return moment(date).format("DD-MMM-YYYY");
      } else {
        return "01/05/2023";
      }
    },
    getAmount(amount) {
      if (amount != null) {
        return `$${amount.toFixed(2)}`;
      } else {
        return "$0.00";
      }
    },
    onHeaderSelectionChange(event) {
      this.selectedListWithVoucherId = [];
      let allChecked = event.event.target.checked;
      this.membersData.items = this.membersData.items.map((item) => {
        if (item?.voucherCode?.length > 5) {
          this.selectedListWithVoucherId.push({
            id: item.id,
            hasVoucherCode: true,
            emailStatus: item.emailStatus,
            isUnassigned: item.isUnassigned,
            voucherCode: item.voucherCode,
            voucherStatus: item.voucherStatus,
            processingStatus: item.processingStatus,
            subscriberStatus: item.subscriberStatus,
          });
        } else {
          this.selectedListWithVoucherId.push({
            id: item.id,
            hasVoucherCode: false,
            emailStatus: item.emailStatus,
            isUnassigned: item.isUnassigned,
            voucherCode: item.voucherCode,
            voucherStatus: item.voucherStatus,
            processingStatus: item.processingStatus,
            subscriberStatus: item.subscriberStatus,
          });
        }
        return { ...item, selected: allChecked };
      });
      if (allChecked) {
        this.selectedItems = [];
        let members = this.membersData.items;
        for (const key in members) {
          if (Object.hasOwnProperty.call(members, key)) {
            this.selectedItems.push(members[key].id);
            this.selectedMemberList.push(members[key]);
          }
        }
      } else {
        this.selectedItems = [];
        this.selectedListWithVoucherId = [];
      }
      this.setChangeStatus();
    },
    onSelectionChange(event) {
      // ;
      event.dataItem[this.selectedField] = !event.dataItem[this.selectedField];
      this.selectedItems = event.dataItems
        .filter((x) => x.selected === true)
        .map((x) => x.id);

      this.selectedMemberList = event.dataItems.filter(
        (x) => x.selected === true
      );
      let hasExistId = false;
      if (event.dataItem.selected) {
        if (this.selectedListWithVoucherId.length) {
          const newSelectedListWithVoucherId =
            this.selectedListWithVoucherId.map((item) => {
              if (item.id === event.dataItem.id) {
                hasExistId = true;
                return {
                  ...item,
                  hasVoucherCode:
                    event?.dataItem?.voucherCode?.length > 5 ? true : false,
                };
              } else {
                return item;
              }
            });
          this.selectedListWithVoucherId = newSelectedListWithVoucherId;
          if (!hasExistId) {
            this.selectedListWithVoucherId.push({
              id: event.dataItem.id,
              hasVoucherCode:
                event?.dataItem?.voucherCode?.length > 5 ? true : false,
              emailStatus: event.dataItem.emailStatus,
              isUnassigned: event.dataItem.isUnassigned,
              voucherCode: event.dataItem.voucherCode,
              voucherStatus: event.dataItem.voucherStatus,
              processingStatus: event.dataItem.processingStatus,
              subscriberStatus: event.dataItem.subscriberStatus,
            });
          }
        } else {
          this.selectedListWithVoucherId.push({
            id: event.dataItem.id,
            hasVoucherCode:
              event?.dataItem?.voucherCode?.length > 5 ? true : false,
            emailStatus: event.dataItem.emailStatus,
            isUnassigned: event.dataItem.isUnassigned,
            voucherCode: event.dataItem.voucherCode,
            voucherStatus: event.dataItem.voucherStatus,
            processingStatus: event.dataItem.processingStatus,
            subscriberStatus: event.dataItem.subscriberStatus,
          });
        }
      } else {
        const index = this.selectedListWithVoucherId.findIndex((item) => {
          return item.id === event.dataItem.id;
        });
        if (index !== -1) this.selectedListWithVoucherId.splice(index, 1);
      }
      this.setChangeStatus();
    },
    setChangeStatus() {
      const allVoucherStatusSame = this.selectedListWithVoucherId.every(
        (item) =>
          item.voucherStatus ===
            this.selectedListWithVoucherId[0].voucherStatus &&
          item.voucherCode != null &&
          item.voucherCode != "Generating..." &&
          item.voucherCode != "" &&
          (item.voucherStatus == 1 ||
            item.voucherStatus == 0 ||
            item.voucherStatus == 2)
      );
      const changeStatusOptionExists = this.moreActionOptions.some(
        (option) => option.id === 5
      );
      if (allVoucherStatusSame && !changeStatusOptionExists) {
        this.moreActionOptions.push({ id: 5, text: "Change Status" });
      }
      if (!allVoucherStatusSame && changeStatusOptionExists) {
        const changeStatusIndex = this.moreActionOptions.findIndex(
          (option) => option.id === 5
        );
        if (changeStatusIndex !== -1) {
          this.moreActionOptions.splice(changeStatusIndex, 1);
        }
      }
    },
    debounceSearch(event) {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.onSearch(event);
      }, 600);
    },
    onSearch(e) {
      this.searchWord = e.value;
      this.$store.commit("LOADING_STATUS", true);
      let payload = {
        clubId: this.clubId,
        limit: this.take,
        voucherCampaignId: this.voucherCampaignId,
        searchText: this.searchWord,
        voucherEmailStatus: this.emailStatusValue.value,
        voucherStatus: this.voucherStatusValue.text,
      };

      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.$store.dispatch("getVoucherRecipients", payload);
      }, 800);
    },
    dataStateChange(event) {
      this.take = event.data.take;
      this.skip = event.data.skip;

      let payload = {
        clubId: +this.clubId,
        limit: event.data.take,
        offset: event.data.skip,
        voucherCampaignId: this.voucherCampaignId,
        searchText: this.searchWord,
        voucherEmailStatus: this.emailStatusValue?.value,
        voucherStatus:
          this.voucherStatusValue.text == "Suspend"
            ? "Suspended"
            : this.voucherStatusValue?.text,
      };

      if (this.isAdvanceFilteringExist) {
        this.advanceFilterPayload.limit = event.data.take;
        this.advanceFilterPayload.offset = event.data.skip;
        payload = {
          ...payload,
          ...this.advanceFilterPayload,
        };
      }

      if (this.sortBy && this.sortDir) {
        payload.orderBy = `${this.sortBy} ${this.sortDir}`;
      }

      this.$store.dispatch("getVoucherRecipients", payload);
    },
    openModal() {
      this.isUploadModalOpen = true;
    },
    closeUploadDataModal() {
      this.isUploadModalOpen = false;
    },
    openMapDataModal(e) {
      this.documentIdPass = e;
      this.isMapDataModalOpen = true;
    },
    closeMapModal() {
      this.isUploadModalOpen = true;
      this.isMapDataModalOpen = false;
    },
    closeAllModals() {
      this.isUploadModalOpen = false;
      this.isMapDataModalOpen = false;
    },
    onItemClick() {
      this.addFromClubopenModal();
    },
    openModalForAddAudienceList() {
      this.$store.commit("MODAL_VISIBLE_COMMON", true);
    },
    addFromClubopenModal() {
      this.addFromClubmodalVisible = true;
      this.addFromClubIsSelectModalOpen = true;
      this.addFromClubisUploadModalOpen = false;
      this.addFromClubisMapDataModalOpen = false;
    },
    addFromClubOpenUploadDataModal() {
      this.addFromClubIsSelectModalOpen = false;
      this.addFromClubisUploadModalOpen = true;
      this.addFromClubisMapDataModalOpen = false;
    },
    addFromClubopenMapDataModal() {
      this.addFromClubisUploadModalOpen = false;
      this.addFromClubisMapDataModalOpen = true;
    },
    addFromClubcloseSelectMemberModal() {
      this.addFromClubIsSelectModalOpen = false;
    },
    addFromClubcloseUploadDataModal() {
      this.addFromClubisUploadModalOpen = false;
      this.addFromClubIsSelectModalOpen = true;
    },
    addFromClubcloseMapModal() {
      this.addFromClubisMapDataModalOpen = false;
      this.addFromClubisUploadModalOpen = true;
    },
    addFromClubcloseAllModals() {
      this.addFromClubIsSelectModalOpen = false;
      this.addFromClubisUploadModalOpen = false;
      this.addFromClubisMapDataModalOpen = false;
    },
    deleteRecipients() {
      this.$store.commit("SET_MODAL_VISIBLE", true);
      this.deleteModalVisiable = true;
    },
    onBulkRefill() {
      this.voucherRecipientIdsForRefillAmount = this.selectedItems;
      this.refillAmountModalVisibility = true;
    },
    onBulkChangeExpiryDate() {
      this.voucherRecipientIdsForChangeExpiryDate = this.selectedItems;
      this.changeExpiryDateModalVisibility = true;
    },
    onBulkChangStatus() {
      this.voucherRecipientIdsForChangeStatus = this.selectedItems;
      this.changeStatusModalVisibility = true;
    },
    sendRecipients() {
      let withoutVoucherCodeRecipients = this.selectedMemberList.filter(
        (x) => !x.voucherCode
      );
      if (withoutVoucherCodeRecipients.length > 0) {
        this.noVoucherIdRecipients = withoutVoucherCodeRecipients;
        this.isCkUserDataAvailableModalOpen = true;
        return;
      }
      let payloadForSendEmail = {
        clubId: this.clubId,
        voucherCampaignId: this.voucherCampaignId,
        voucherCampaignRecipientIds: this.selectedMemberList.map((x) => x.id),
      };
      const payload = {
        clubId: this.clubId,
        voucherCampaignId: this.voucherCampaignId,
        searchText: this.searchWord,
        voucherEmailStatus: this.emailStatusValue.value,
        voucherStatus: this.voucherStatusValue.text,
        limit: this.take,
        offset: this.skip,
      };

      this.$store.commit("LOADING_STATUS", true);
      sendVoucherEmailService(payloadForSendEmail)
        .then(() => {
          this.selectedItems = [];
          this.selectedMemberList = [];
          this.selectedListWithVoucherId = [];
          this.$store.dispatch("getVoucherRecipients", payload);
          this.$store.commit("LOADING_STATUS", false);
          this.$store.commit("SET_SHOW_MESSAGE", true);
          let model = new SnackBarModel();
          model.type = "success";
          model.Class = "status-error";
          model.Message =
            "Voucher emails have been sent to the selected audiences.";
          this.$store.commit("SET_CONTROL_STATUS", model);
          this.$store.commit("SET_SHOW_MESSAGE", true);
          setTimeout(() => {
            this.$store.commit("SET_SHOW_MESSAGE", false);
          }, 3000);
        })
        .catch((error) => {
          this.$store.commit("LOADING_STATUS", false);
          this.$store.commit("LOADING_STATUS", false);
          this.$store.commit("SET_SHOW_MESSAGE", true);
          let model = new SnackBarModel();
          model.type = "error";
          model.Class = "status-error";
          model.Message = "Something went wrong.";
          this.$store.commit("SET_CONTROL_STATUS", model);
          this.$store.commit("SET_SHOW_MESSAGE", true);
          setTimeout(() => {
            this.$store.commit("SET_SHOW_MESSAGE", false);
          }, 3000);

          // ;
        });
    },
    closeDataUnavailableUserModal() {
      this.isCkUserDataAvailableModalOpen = false;
    },
    getRecipient() {
      let payload = {
        clubId: this.clubId,
        voucherCampaignId: this.voucherCampaignId,
      };
      this.$store.dispatch("getVoucherRecipients", payload);
    },
    handlevoucherStatusValue(e) {
      this.voucherStatusValue = e.value;
      let payload = {
        clubId: this.clubId,
        voucherCampaignId: this.voucherCampaignId,
        searchText: this.searchWord,
        voucherEmailStatus: this.emailStatusValue?.value,
        voucherStatus:
          e.value.text == "Suspend"
            ? "Suspended"
            : this.voucherStatusValue?.text,
        limit: this.take,
        offset: this.skip,
      };
      this.$store.dispatch("getVoucherRecipients", payload);
    },
    handleEmailStatusValue(e) {
      this.emailStatusValue = e.value;
      let payload = {
        clubId: this.clubId,
        voucherCampaignId: this.voucherCampaignId,
        searchText: this.searchWord,
        voucherEmailStatus: this.emailStatusValue?.value,
        voucherStatus: this.voucherStatusValue?.text,
        limit: this.take,
        offset: this.skip,
      };
      this.$store.dispatch("getVoucherRecipients", payload);
    },
    closeMemberDetailsModal() {
      this.isViewDetailsModalOpen = false;
    },

    generatePayload() {
      let payload = {
        clubId: +this.clubId,
        voucherCampaignId: this.voucherCampaignId,
        searchText: this.searchWord,
        voucherEmailStatus: this.emailStatusValue?.value,
        voucherStatus:
          this.voucherStatusValue.text == "Suspend"
            ? "Suspended"
            : this.voucherStatusValue?.text,
        // limit: this.take,
        // offset: this.skip,
      };

      if (this.showUnassignedVouchersOnly) {
        payload.showUnassignedVouchersOnly = true;
      }

      if (this.voucherCode) {
        payload.voucherCodeFilterValue = this.voucherCode;
      }

      if (this.createdOnStartDate && this.createdOnEndDate) {
        payload.minCreatedTime = this.createdOnStartDate;
        payload.maxCreatedTime = this.createdOnEndDate;
      }

      if (this.redeemedAmount === 0 || this.redeemedAmount > 0) {
        payload.amountFilterType = this.amountTypeValue.value;
        payload.amountFilterValue = this.redeemedAmount;
      }

      if (
        this.DateFilterType === 4 &&
        this.DateFilterStartDate &&
        this.DateFilterEndDate
      ) {
        payload.dateFilterType = this.DateFilterType;
        payload.dateFilterStartDate = this.DateFilterStartDate;
        payload.dateFilterEndDate = this.DateFilterEndDate;
      }

      if (this.redeemedDate && [1, 2, 3].includes(this.DateFilterType)) {
        payload.dateFilterType = this.DateFilterType;
        payload.dateFilterStartDate = this.redeemedDate;
      }

      return payload;
    },
    exportVoucherAudience() {
      const payload = this.generatePayload();
      this.$store.dispatch("voucherRecipientsExport", payload);
    },
    selectedExport() {
      let payload = {
        clubId: +this.clubId,
        voucherCampaignId: this.voucherCampaignId,
        voucherRecipientIds: this.selectedItems,
      };
      this.$store.dispatch("voucherRecipientsExport", payload);
    },
    selectedMembersList(members) {
      this.selectedMembers = members;
    },
    advanceFilterShowHandler() {
      this.advanceFilterShow = !this.advanceFilterShow;
    },
    genetareVoucherCode() {
      this.isLoading = true;
      const payload = {
        clubId: +this.clubId,
        voucherCampaignRecipientIds: this.selectedItems,
        voucherCampaignId: this.voucherCampaignId,
        searchText: this.searchWord,
        limit: this.take,
        offset: this.skip,
      };
      this.copyOfSelectedItems = this.selectedItems;
      this.$store.dispatch("generateVoucherCode", payload, this.selectedItems);
      setTimeout(() => {
        this.$store.commit(
          "SET_GENERATING_VOUCHER_CODE_SNACKBAR_VISIBILITY",
          true
        );
        // this.membersData.items = this.membersData.items.map((item) => {
        //   if (this.copyOfSelectedItems.includes(item.id)) {
        //     return {
        //       ...item,
        //       voucherCode: "Generating...",
        //       selected: false,
        //     };
        //   } else {
        //     return item;
        //   }
        // });
        this.selectedItems = [];
        this.selectedMemberList = [];
        this.isLoading = false;
        this.selectedListWithVoucherId = [];
      }, 1000);
    },

    advanceFilterDataValidation() {
      // if (
      //   !this.voucherCode &&
      //   !this.createdOnStartDate &&
      //   !this.createdOnEndDate &&
      //   !this.usedAmountStartRange &&
      //   !this.usedAmountEndRange
      // ) {
      //   this.showNotification(
      //     "Please input any fields to proceed with advance filter.",
      //     "warning"
      //   );
      //   return false;
      // }

      if (this.createdOnStartDate && this.createdOnEndDate) {
        if (this.createdOnStartDate > this.createdOnEndDate) {
          this.showNotification(
            "Please input valid created time range.",
            "warning"
          );
          return false;
        }
      }
      if (
        this.DateFilterType == 4 &&
        this.DateFilterStartDate &&
        this.DateFilterEndDate
      ) {
        if (this.DateFilterStartDate > this.DateFilterEndDate) {
          this.showNotification("Please input valid  time range.", "warning");
          return false;
        }
      }

      return true;
    },

    closeAdvanceFilter() {
      this.advanceFilterShow = false;
    },

    applyAdvanceFiltering() {
      if (this.advanceFilterDataValidation()) {
        this.isAdvanceFilteringExist = true;

        if (this.clearAdvanceFilterFields) {
          this.clearAdvanceFilterFields = false;
          this.isAdvanceFilteringExist = false;
        }

        //
        //   "x123",
        //   this.clearAdvanceFilterFields,
        //   this.isAdvanceFilteringExist
        // );
        this.advanceFilterShow = false;
        const payload = this.generatePayload();
        this.advanceFilterPayload = payload;

        payload.limit = this.take;

        this.$store.dispatch("getVoucherRecipients", payload);
      }
    },

    clearAdvanceFilterField() {
      this.clearAdvanceFilterFields = true;
      this.voucherCode = "";
      this.createdOnStartDate = "";
      this.createdOnEndDate = "";
      this.amountTypeValue = { id: 1, text: "=", value: 1 };
      this.DateFilterType = 1;
      this.redeemedAmount = "";
      this.redeemedDate = "";
      this.DateFilterStartDate = "";
      this.DateFilterEndDate = "";
      this.showUnassignedVouchersOnly = false;
    },

    updateRedeemedDate(event) {
      this.redeemedDate = event.target.value;
      // ;
    },
    updateRedeemedBetweenStartDate(event) {
      this.DateFilterStartDate = event.target.value;
    },
    updateRedeemedBetweenEndDate(event) {
      this.DateFilterEndDate = event.target.value;
    },
    handleAmountTypeChanges(e) {
      this.amountTypeValue = e.target.value;
      // ;
    },
    showNotification(message, type) {
      this.notifyMessage = message;
      this.notifyType = type;
      this.isNotify = true;
      setTimeout(
        () => (
          (this.notifyMessage = ""),
          (this.notifyType = "success"),
          (this.isNotify = false)
        ),
        4000
      );
    },
  },
};
</script>
