<modal
  :class="'max-height-100-145'"
  title="Change Status"
  :handler="true"
  @modalHandler="this.onCancel"
  :cssClass="'c-modal-small'"
>
  <template v-slot:content>
    <div>
      <div class="text-start">
        <label>Voucher Status</label>
        <dropdownlist
          :class="'w-100-vsm mb-24'"
          :style="{  }"
          :data-items="voucherStatusesValues"
          :text-field="'text'"
          :data-item-key="'id'"
          :value="voucherStatusValue"
          @change="handlevoucherStatusValue"
        >
        </dropdownlist>
      </div>
    </div>
  </template>
  <template v-slot:footer>
    <div class="d-flex justify-content-between">
      <div class="me-1 w-50-p">
        <kbutton @click="this.onCancel" :class="'w-100'">Cancel</kbutton>
      </div>
      <div class="ms-1 w-50-p">
        <kbutton
          @click="this.onSubmitHandler"
          :theme-color="'primary'"
          :class="'w-100'"
          >Change status</kbutton
        >
      </div>
    </div>
  </template>
</modal>

<AppSnackBar
  :message="this.notifyMessage"
  :notificationType="this.notifyType"
  v-if="this.isNotify"
></AppSnackBar>
