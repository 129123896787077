<modal title="Delete Recipients" :handler="true" :cssClass="'c-modal-small'" :footer="false" @modalHandler="onCancel">
  <template v-slot:content>
    <div>
      <p class="font-16 m-0 py-5 text-center">
        Are you sure you want to delete selected recipient from this voucher recipient{{isMultitpleRecipient ? '(s)' :
        ''}} list?
      </p>
      <dialog-actions-bar>
        <kbutton @click="onCancel">Cancel</kbutton>
        <kbutton :theme-color="'primary'" @click="onConfirm">Delete</kbutton>
      </dialog-actions-bar>
    </div>
  </template>
</modal>