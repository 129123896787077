<modal :class="'max-height-450'" title="Cannot Send Email" :handler="true" :cssClass="'c-modal-small'"
  @modalHandler="modalHandler">
  <template v-slot:content>
    <div>
      <p class="font-16 m-0 py-2 text-center">
        Please generate a voucher code first for following users: {{memberList}}
      </p>
    </div>
  </template>
  <template v-slot:footer>
    <kbutton @click="modalHandler">Close</kbutton>
  </template>
</modal>