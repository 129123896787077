<template src="./ManageRecipientUnAssignedDeleteModal.html"></template>

<script>
import { Dialog, DialogActionsBar } from "@progress/kendo-vue-dialogs";
import { Button } from "@progress/kendo-vue-buttons";
import { Input } from "@progress/kendo-vue-inputs";
import modal from "@/components/common/AppModal/AppModal.vue";

export default {
  components: {
    "k-dialog": Dialog,
    "dialog-actions-bar": DialogActionsBar,
    kbutton: Button,
    "k-input": Input,
    modal,
  },
  props: {
    deletePayload: {
      type: Object,
    },
    isMultitpleRecipient: {
      required: false,
    },
    onCancel: { type: Function },
    onConfirm: { type: Function },
  },
  data: function () {
    return {
      visibleDialog: true,
      clubId: this.$route.params.id,
      title: "",
      message: "",
    };
  },
  mounted() {
    ;
  },
};
</script>

<style></style>
