<!-- <h1>{{template_id}}</h1> -->
<AppSnackBar
  :message="showSuccessMsg.Message"
  :notificationType="showSuccessMsg.type"
  v-if="isShowSuccessMsg"
>
</AppSnackBar>
<AppSnackBar
  :message="showErrorMsg.Message"
  :notificationType="showErrorMsg.type"
  v-if="isShowErrorMsg"
></AppSnackBar>
<AppSnackBar
  :message="notifyMessage"
  :notificationType="notifyType"
  v-if="isNotify"
></AppSnackBar>
<app-loader
  v-if="isLoading || assignAudienceLoading || updateRefillAmountLoading || updateExpiryDateLoading || recipientsListLoading || addVoucherLoading"
>
</app-loader>

<div class="dashboard-right-inner">
  <!--breadcrumb-->
  <div class="breadcrumb" v-if="isBreadCrumb">
    <a href="#">
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.0025 2.38854C10.4323 1.87627 9.56767 1.87626 8.9975 2.38854L3.4975 7.33008C3.18086 7.61457 3 8.0202 3 8.44587V15.5C3 16.3284 3.67157 17 4.5 17H6.5C7.32843 17 8 16.3284 8 15.5V11.5C8 11.2239 8.22386 11 8.5 11H11.5C11.7761 11 12 11.2239 12 11.5V15.5C12 16.3284 12.6716 17 13.5 17H15.5C16.3284 17 17 16.3284 17 15.5V8.44587C17 8.0202 16.8191 7.61457 16.5025 7.33008L11.0025 2.38854Z"
          fill="#6C707D"
        />
      </svg>
    </a>
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.93934 4.06066L8.87872 8.00003L4.93934 11.9394L6 13L11 8.00003L6 3L4.93934 4.06066Z"
        fill="#ADB2BD"
      />
    </svg>
    <a class="color-sky-gray" href="#">FC Brisbane Warriors</a>
  </div>

  <div class="dashboard-content bg-white px-15">
    <div>
      <Grid
        ref="grid"
        :class="'first-col-checkbox-center manage-recipients-table table-design-2 th-td-checkbox-style-2 sorting-btn-th mb-60'"
        :data-items="membersData.items"
        :take="take"
        :skip="skip"
        :total="total"
        :pageable="gridPageable"
        :selectable="true"
        :selected-field="selectedField"
        :columns="columns"
        :sortable="true"
        :sort="sort"
        @sortchange="sortChangeHandler"
        @selectionchange="onSelectionChange"
        @datastatechange="dataStateChange"
        @headerselectionchange="onHeaderSelectionChange"
      >
        <template v-slot:emailCell="{props}">
          <td>
            <span
              v-if="props.dataItem.isUnassigned"
              class="k-icon k-i-close color-danger"
            ></span>
            <i v-if="props.dataItem.isUnassigned"
              >{{ ' ' + props.dataItem.email}}</i
            >
            <span v-if="!props.dataItem.isUnassigned"
              >{{ ' ' + props.dataItem.email}}</span
            >
          </td>
        </template>

        <template v-slot:nameCell="{props}">
          <td>
            <span
              v-if="props.dataItem.isUnassigned"
              class="k-icon k-i-close color-danger"
            ></span>
            <i v-if="props.dataItem.isUnassigned"
              >{{ ' ' + props.dataItem.name}}</i
            >
            <span v-if="!props.dataItem.isUnassigned"
              >{{ ' ' + props.dataItem.name}}</span
            >
          </td>
        </template>

        <template v-slot:emailStatusCell="{props}">
          <td>
            <span
              v-if="props.dataItem.emailStatus == 0"
              class="k-icon k-i-close color-blue"
            ></span>
            <span
              v-if="props.dataItem.emailStatus == 1"
              class="k-icon k-i-folder-add color-violet"
            ></span>
            <span
              v-if="props.dataItem.emailStatus == 2"
              class="k-icon k-i-login color-success"
            ></span>
            <span
              v-if="props.dataItem.emailStatus == 3"
              class="k-icon k-i-folder-add color-violet"
            ></span>
            <span
              v-if="props.dataItem.emailStatus == 4"
              class="k-icon k-i-clock color-success"
            ></span>
            <span
              v-if="props.dataItem.emailStatus == 5"
              class="k-icon k-i-check color-success"
            ></span>
            <span
              v-if="props.dataItem.emailStatus == 6"
              class="k-icon k-i-warning color-danger"
            ></span>
            <span class="ps-2"
              >{{emailStatusArray?.[props.dataItem.emailStatus]}}</span
            >
          </td>
        </template>

        <GridNoRecords> No recipients/audience found </GridNoRecords>
        <template v-slot:voucherStatusCell="{props}">
          <td>
            <div class="d-flex justify-content-between">
              <div class="left voucher-manage-recipients-status-btn radius-4">
                <buttongroup>
                  <kbutton
                    :disabled="([3,4].includes(props?.dataItem?.voucherStatus) || props?.dataItem?.voucherCode == 'Generating...' || props?.dataItem?.voucherCode == '') || !hasEditAccess()"
                    :class="'hover-none-btn cursor-normal radius-4 h-38'"
                    :size="'medium'"
                  >
                    {{props.dataItem.voucherStatus == 0 ? "Inactive" :
                    props.dataItem.voucherStatus == 1 ? "Active" :
                    props.dataItem.voucherStatus == 2 ? "Suspended" :
                    props.dataItem.voucherStatus == 4 ? "Expired" : "Redeemed"}}
                  </kbutton>
                  <DropDownButton
                    :disabled="([3,4].includes(props?.dataItem?.voucherStatus) || props?.dataItem?.voucherCode == 'Generating...' || props?.dataItem?.voucherCode == '') || !hasEditAccess()"
                    @open="onOpen(props.dataItem.voucherStatus)"
                    :class="'radius-0 ml--4'"
                    :button-class="'radius-4'"
                    :popup-settings="popupSettings"
                    :size="'small'"
                    :icon="'arrow-60-down'"
                    :items="voucherStatuses"
                    @itemclick="onVoucherStatusItemClick($event, props.dataItem.id)"
                  />
                </buttongroup>
              </div>
              <div class="right">
                <AppPopUp
                  :buttonClass="'btn-clear h-40'"
                  :voucher="props.dataItem"
                  v-if="(![2,3,4].includes(props.dataItem.voucherStatus) && props?.dataItem?.voucherCode != 'Generating...') && hasEditAccess()"
                  :class="'btn-dot-light'"
                  :items="props.dataItem.isUnassigned ?  voucherOptionsForUnassigned : props.dataItem.voucherStatus == 1 ? voucherOptionsForAssignedAndActive : voucherOptionsForAssigned"
                  @dropId="getDropIdForVoucherOption"
                  :icon="'more-vertical'"
                  :popUpSettings="popupSettings120"
                >
                </AppPopUp>

                <AppPopUp
                  :buttonClass="'btn-clear'"
                  :voucher="props.dataItem"
                  v-if="([4].includes(props.dataItem.voucherStatus) && props?.dataItem?.voucherCode != 'Generating...') && hasEditAccess()"
                  :class="'btn-dot-light'"
                  :items="voucherExpiredOptionsForAssigned"
                  @dropId="getDropIdForVoucherOption"
                  :icon="'more-vertical'"
                  :popUpSettings="popupSettings120"
                >
                </AppPopUp>
              </div>
            </div>
          </td>
        </template>

        <template v-slot:actions="{props}">
          <td></td>
        </template>

        <toolbar>
          <div class="search-box-wrap width-220 w-100-vsm me-3 mr-0-vsm">
            <span class="k-icon k-i-zoom"></span>
            <span class="k-textbox k-grid-search k-display-flex">
              <k-input
                :class="'mb-0 width-220 w-100-vsm'"
                placeholder="Search"
                @input="debounceSearch"
              >
              </k-input>
            </span>
          </div>

          <div class="pe-3 pr-0-vsm flex-wrap w-100-vsm">
            <label class="pe-2 mb-0 w-100-vsm">Email Status</label>
            <dropdownlist
              :class="'w-100-vsm'"
              :disabled="voucherStatusValue.text=='Active'"
              :style="{ width: '160px' }"
              :data-items="emailStatuses"
              :text-field="'text'"
              :data-item-key="'id'"
              :value="emailStatusValue"
              @change="handleEmailStatusValue"
            >
            </dropdownlist>
          </div>

          <div class="pe-3 pr-0-vsm flex-wrap w-100-vsm">
            <label class="pe-2 mb-0 w-100-vsm">Voucher Status</label>
            <dropdownlist
              :class="'w-100-vsm'"
              :style="{ width: '160px' }"
              :data-items="voucherStatusesValues"
              :text-field="'text'"
              :data-item-key="'id'"
              :value="voucherStatusValue"
              @change="handlevoucherStatusValue"
            >
            </dropdownlist>
          </div>

          <div v-if="selectedItems.length == 0" class="pe-3 pr-0-vsm w-100-vsm">
            <kbutton
              :fill-mode="'flat'"
              :theme-color="'primary'"
              :icon="'filter'"
              @click="advanceFilterShowHandler"
              ref="advanceFilterRef"
              :class="'w-100-vsm'"
            >
              Advanced Filtering
            </kbutton>
            <Popup
              :anchor="'advanceFilterRef'"
              :show="advanceFilterShow"
              :popup-class="'advance-filter-popup'"
            >
              <div class="advance-filter-popup-wrap">
                <div
                  class="head d-flex justify-content-between align-items-center p-16"
                >
                  <h5 class="font-16 m-0">Advanced Filtering</h5>
                  <kbutton
                    :fill-mode="'flat'"
                    @click="clearAdvanceFilterField"
                    :icon="'close'"
                    >Clear</kbutton
                  >
                </div>
                <div class="p-24">
                  <div>
                    <label class="" for="">Voucher Code</label>
                    <k-input
                      type="text"
                      v-model="voucherCode"
                      placeholder="Type the code"
                    ></k-input>
                  </div>
                  <div>
                    <checkbox
                      :class="'font-14-vsm'"
                      :value="this.showUnassignedVouchersOnly"
                      @change="this.handleVoucherCheckBoxStatus"
                      :label="'Show Unassigned vouchers only'"
                    />
                  </div>
                  <br />
                  <div class="row">
                    <div class="col-md-12">
                      <label class="" for="">Created Between</label>
                    </div>
                    <div class="col-md-6">
                      <div>
                        <datePicker
                          :format="'dd-MMM-yyyy'"
                          @change="updateCreatedOnStartDate"
                          :max="new Date()"
                          :value="createdOnStartDate"
                        >
                        </datePicker>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div>
                        <datePicker
                          :format="'dd-MMM-yyyy'"
                          @change="updateCreatedOnEndDate"
                          :value="createdOnEndDate"
                        >
                        </datePicker>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-12 mb-2">
                      <label class="" for="">Expiry Date</label>
                    </div>
                    <div class="col-md-12">
                      <div class="d-flex justify-content-between mb-3">
                        <radiogroup
                          :default-value="dataItems[2].value"
                          :data-items="dataItems"
                          :layout="'horizontal'"
                          :value="DateFilterType"
                          @change="handleChange"
                        />
                      </div>
                    </div>

                    <div v-if="DateFilterType === 4" class="col-md-6">
                      <div>
                        <datePicker
                          :format="'dd-MMM-yyyy'"
                          @change="updateRedeemedBetweenStartDate"
                          :value="DateFilterStartDate"
                        >
                        </datePicker>
                      </div>
                    </div>
                    <div v-if="DateFilterType == 4" class="col-md-6">
                      <div>
                        <datePicker
                          :format="'dd-MMM-yyyy'"
                          @change="updateRedeemedBetweenEndDate"
                          :value="DateFilterEndDate"
                        >
                        </datePicker>
                      </div>
                    </div>
                    <div v-if="DateFilterType != 4" class="col-md-12">
                      <div>
                        <datePicker
                          :format="'dd-MMM-yyyy'"
                          @change="updateRedeemedDate"
                          :value="redeemedDate"
                        >
                        </datePicker>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12 mb-2">
                      <label class="" for="">Voucher Amount</label>
                    </div>
                    <div class="col-12">
                      <div class="row">
                        <div
                          class="col-sm-6 col-7 pe-1 pe-md-2 mb-2 mb-sm-0 d-flex justify-content-between align-content-center"
                        >
                          <label class="mb-0 h-38 l-height-38">Amount</label>
                          <dropdownlist
                            :style="{ width: '80px' }"
                            :data-items="amountType"
                            :text-field="'text'"
                            :class="'w-60-vsm'"
                            :data-item-key="'id'"
                            :value="amountTypeValue"
                            @change="handleAmountTypeChanges"
                          >
                          </dropdownlist>
                        </div>
                        <div class="col-sm-6 col-5">
                          <numericTextBox v-model="redeemedAmount" :min="0">
                          </numericTextBox>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="d-flex justify-content-between">
                    <div class="me-1 w-50-p">
                      <kbutton @click="closeAdvanceFilter" :class="'w-100'">
                        Cancel
                      </kbutton>
                    </div>
                    <div class="ms-1 w-50-p">
                      <kbutton
                        @click="applyAdvanceFiltering"
                        :theme-color="'primary'"
                        :class="'w-100'"
                      >
                        Apply
                      </kbutton>
                    </div>
                  </div>
                </div>
              </div>
            </Popup>
          </div>

          <div class="ms-auto ml-unset-1650 w-100-vsm flex-wrap">
            <div
              v-if="selectedItems.length == 0 "
              class="ms-auto ml-unset-1650 w-100-vsm"
            >
              <div class="d-flex w-100-vsm">
                <buttongroup class="me-sm-3 me-0 w-100--44-vsm">
                  <kbutton
                    :disabled="!hasAudienceCreateAccess() && !hasAudienceEditAccess()"
                    :theme-color="'primary'"
                    :class="'w-100-vsm'"
                    @click="openModalForAddAudienceList"
                    >Add Audience List
                  </kbutton>
                  <DropDownButton
                    :disabled="!hasAudienceCreateAccess() && !hasAudienceEditAccess()"
                    :class="'ml--2 bgdark-blue btn-size-38'"
                    buttonClass="btn-radius-0-8-8-0 btn-bg-dark-blue"
                    :popup-settings="popupSettings"
                    :theme-color="'primary'"
                    :items="addAudienceDropDown"
                    :icon="'arrow-60-down'"
                    @itemclick="onItemClick"
                  />
                </buttongroup>

                <AppPopUp
                  :class="'btn-dot-light h-38 ms-auto ms-sm-0'"
                  :items="moreOptionDropdownitems"
                  @dropId="getDropId"
                  :icon="'more-vertical'"
                  :buttonClass="'btn-clear'"
                  :popUpSettings="popupSettings"
                >
                </AppPopUp>
              </div>
            </div>

            <div
              v-if="selectedItems.length > 0 && generateCodeButtonVisibility"
              class="w-100-vsm"
            >
              <kbutton
                :disabled="!hasCreateAccess() && !hasEditAccess()"
                @click="genetareVoucherCode "
                :class="'me-3 mr-0-vsm btn-bg-white border-black w-100-vsm'"
                :icon="'reload'"
                >Generate Code</kbutton
              >
            </div>

            <div
              v-if="selectedItems.length > 0 && sendEmailButtonVisibility"
              class="w-100-vsm mb-2 mb-sm-0"
            >
              <kbutton
                :disabled="!hasCreateAccess() && !hasEditAccess()"
                @click="sendRecipients"
                :theme-color="'primary'"
                :class="'me-1 w-100-vsm'"
                >Send Email</kbutton
              >
            </div>

            <buttongroup
              v-if="selectedItems.length > 0"
              :class="'ms-3 ml-0-vsm w-100-vsm'"
            >
              <DropDownButton
                :disabled="!hasCreateAccess() && !hasEditAccess()"
                :class="'btn-hover-none color-black border-black dropdownbutton-icon-right h-38 w-100-vsm btn-100'"
                :popup-settings="popupSettings"
                :text="'More Actions'"
                :size="'small'"
                :icon="'arrow-60-down'"
                :theme-color="'primary'"
                :fill-mode="'outline'"
                :items="moreActionOptions"
                @itemclick="moreActionsHandler($event)"
              />
            </buttongroup>
          </div>
        </toolbar>
      </Grid>

      <UploadDataForVoucherModal
        v-if="isUploadModalOpen"
        @closeUploadDataModal="closeUploadDataModal"
        @callBackDataForDigitalPass="openMapDataModal"
      />
      <MapDataToVoucherFieldsModal
        v-if="isMapDataModalOpen"
        :documentId="documentIdPass"
        @closeMapModal="closeMapModal"
        @closeAllModals="closeAllModals"
      />

      <SelectMemberModal
        v-if="addFromClubIsSelectModalOpen"
        @selectedUserIds="selectedMembersList($event)"
        @callBackSelectMemberAdd="addFromClubOpenUploadDataModal"
        @closeSelectMemberModalAdd="addFromClubcloseSelectMemberModal"
      />

      <SelectAnAudienceListModal v-if="isModalVisible" />

      <UploadDataForVoucherModal
        v-if="addFromClubisUploadModalOpen"
        @closeUploadDataModalAdd="addFromClubcloseUploadDataModal"
      />
      <MapDataToVoucherFieldsModal
        v-if="addFromClubisMapDataModalOpen"
        :documentId="documentIdPass"
        :userIds="selectedMembers"
        @closeAllModalsAdd="addFromClubcloseAllModals"
        @closeMapModalAdd="addFromClubcloseMapModal"
      />

      <RecipientsMemberDetails
        v-if="isViewDetailsModalOpen"
        @closeMemberDetailsModal="closeMemberDetailsModal"
      >
      </RecipientsMemberDetails>

      <DataUnavailableUserModal
        v-if="isCkUserDataAvailableModalOpen"
        @closeDataUnavailableUserModal="closeDataUnavailableUserModal"
        :users="noVoucherIdRecipients"
      >
      </DataUnavailableUserModal>

      <ManageRecipientsDeleteModal
        v-if="isDeleteOpen"
        :selectedItems="selectedItems"
      >
      </ManageRecipientsDeleteModal>

      <ManageRecipientUnAssignedDeleteModal
        :onCancel="onCancelDeleteRecipientModal"
        :onConfirm="onConfirmDeleteRecipientModal"
        :isMultitpleRecipient="voucherIdForDeleteRecipient ? false : true"
        v-if="deleteRecipientModalVisibility"
      >
      </ManageRecipientUnAssignedDeleteModal>
    </div>
  </div>
  <SelectRecipientFromVoucherAudienceModalVue
    :payloadForAssign="payload_for_assign_audience_to_recipient"
    v-if="unassigned_voucher_assign_modal_visibility"
  >
  </SelectRecipientFromVoucherAudienceModalVue>

  <GeneratingVoucherCodeSnackbar
    v-if="generate_voucher_code_snackbar_visibility"
  ></GeneratingVoucherCodeSnackbar>
  <GeneratingVoucherStatusSnackbar
    v-if="generate_voucher_status_snackbar_visibility"
  ></GeneratingVoucherStatusSnackbar>

  <RefillVoucherAmountModal
    v-if="refillAmountModalVisibility"
    :onCancel="onCancelRefillAmountModal"
    :onConfirm="onConfirmRefillAmountModal"
  ></RefillVoucherAmountModal>

  <ChangeExpiryDateModal
    v-if="changeExpiryDateModalVisibility"
    :onCancel="onCancelExpiryDateChangeModal"
    :onConfirm="onConfirmExpiryDateChangeModal"
  ></ChangeExpiryDateModal>

  <ChangeStatusModal
    v-if="changeStatusModalVisibility"
    :onCancel="onCancelStatusChangeModal"
    :onConfirm="onConfirmStatusChangeModal"
  ></ChangeStatusModal>

  <BulkActionSnacbar
    v-if="refillBulkActionSnackbarVisibility"
    :onCancel="onCancelRedillSnackbar"
    :modalDetails="refillSnackBarModalDetails"
  />

  <BulkActionSnacbar
    v-if="changeExpiryDateBulkActionSnackbarVisibility"
    :onCancel="onCancelChangeExpiryDateSnackbar"
    :modalDetails="changeExpiryDateSnackBarModalDetails"
  />

  <BulkActionSnacbar
    v-if="bulkDeleteSnackbarVisibility"
    :onCancel="onCancelBulkDeleteSnackbar"
    :modalDetails="bulkDeleteSnackBarModalDetails"
  />
</div>
