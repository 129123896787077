<modal
  v-if="!modalDetails?.isWithoutCount"
  :title="modalDetails?.title"
  :handler="true"
  :cssClass="'c-modal-lg snack-bar-popup justify-content-start'"
  @modalHandler="this.onCancel"
>
  <template v-slot:content>
    <p
      v-if="this.modalDetails?.successCount"
      class="text-center mb-2 pb-1 font-14"
    >
      {{this.modalDetails?.successFirstMessage}}
      <span class="k-icon k-i-check-outline"></span>
      <strong class="text-success"
        >{{this.modalDetails?.successCount}}
        voucher{{this.modalDetails?.successCount == 1 ? '' :
        's'}}{{!this.modalDetails?.successSecondMessage ? '.' : ''}}</strong
      >
      {{this.modalDetails?.successSecondMessage}}
    </p>
    <p
      v-if="this.modalDetails?.errorCount"
      class="text-center mb-2 pb-1 font-14"
    >
      <span class="k-icon k-i-warning"></span
      >{{this.modalDetails?.errorFirstMessage}}<strong class="text-danger"
        >{{this.modalDetails?.errorCount}}
        voucher{{this.modalDetails?.errorCount == 1 ? '' :
        's'}}{{!this.modalDetails?.errorSecondMessage ? '.' : ''}}</strong
      >
      {{this.modalDetails?.errorSecondMessage}}
    </p>
  </template>
</modal>

<modal
  v-if="modalDetails?.isWithoutCount"
  :title="modalDetails?.title"
  :handler="true"
  :cssClass="'c-modal-lg snack-bar-popup justify-content-start'"
  @modalHandler="this.onCancel"
>
  <template v-slot:content>
    <p class="text-center mb-2 pb-1 font-14">{{modalDetails?.body}}</p>
  </template>
</modal>
