<template src="./SelectRecipientFromVoucherAudienceModal.html"></template>
<style lang="scss">
@import "./SelectRecipientFromVoucherAudienceModal.scss";
</style>

<script>
import { Grid, GridToolbar, GridNoRecords } from "@progress/kendo-vue-grid";
import { Input } from "@progress/kendo-vue-inputs";
import { Button, ButtonGroup } from "@progress/kendo-vue-buttons";
import { DropDownList } from "@progress/kendo-vue-dropdowns";
import { DropDownButton } from "@progress/kendo-vue-buttons";
import AddAudience from "../../audience/allAudience/modals/addAudienceModal/AddAudienceModal.vue";
import ImportDataModal from "../../audience/allAudience/modals/importDataModal/ImportDataModal";
import importCustomFieldMapping from "../../audience/allAudience/modals/importCustomFieldMapping/ImportCustomFieldMapping.vue";
import ConfigureFields from "../../audience/allAudience/configureFields/ConfigureFields.vue";
import Breadcrumb from "../../../common/AppBreadCrumbs/BreadCrumbs.vue";
import GetAudienceModel from "@/models/audience/allAudience/GetAudienceModel";
import AllAudienceDeleteModal from "../../audience/allAudience/modals/allAudienceDeleteModal/AllAudienceDeleteModal";
import axios from "axios";
import AppSnackBar from "../../../common/AppSnackBar/AppSnackBar";
import AppLoader from "../../../common/AppLoader/AppLoader";
import SnackBarModel from "@/models/snackBar/snackBarModel";
import { configuration } from "@/configurationProvider";
import { auth } from "@/services/auth.service";
import { Tooltip } from "@progress/kendo-vue-tooltip";
import modal from "../../../common/AppModal/AppModal.vue";
import { Switch } from "@progress/kendo-vue-inputs";
import { DialogActionsBar } from "@progress/kendo-vue-dialogs";
export default {
  name: "Tags",
  components: {
    Grid: Grid,
    buttongroup: ButtonGroup,
    toolbar: GridToolbar,
    "k-input": Input,
    kbutton: Button,
    dropdownlist: DropDownList,
    DropDownButton,
    Breadcrumb,
    AddAudience,
    ImportDataModal,
    importCustomFieldMapping,
    ConfigureFields,
    AllAudienceDeleteModal,
    AppSnackBar,
    AppLoader,
    GridNoRecords,
    Tooltip,
    modal,
    "k-switch": Switch,
    "dialog-actions-bar": DialogActionsBar,
  },
  props: {
    payloadForAssign: {
      type: Object,
    },
  },
  data() {
    return {
      popupSettings: {
        anchorAlign: {
          horizontal: "right",
          vertical: "bottom",
        },
        popupAlign: {
          horizontal: "right",
          vertical: "top",
        },
      },
      clubId: this.$route.params.id,
      searchWord: "",
      selectedField: "selected",
      importModalVisible: false,
      AddAudienceModalVisible: false,
      gridPageable: {
        buttonCount: 5,
        info: true,
        type: "numeric",
        pageSizes: [10, 50, 100, 200, 500, 1000],
        previousNext: true,
      },
      selectedItems: [],
      documentIdPass: "",
      selectedMembers: [],
      selectedMemberList: [],
      audienceDropDown: [{ id: 0, text: "All" }],
      audienceTagDropDown: [{ id: 0, text: "All" }],
      drodownImportExport: [
        { id: 0, text: "Import Data" },
        { id: 1, text: "Export Audience" },
      ],
      passListStatusValue: { id: 0, text: "All" },
      passTagStatusValue: { id: 0, text: "All" },
      openMembermapping: false,
      // modalVisible: true,

      passList: [],

      closeMemberImport: true,
      importedDocId: "",
      isOpenConfigureFields: false,
      pramsTagId: null,
      dataHave: [],
      allChecked: false,
      timer: null,
      notifyMessage: "Success",
      notifyType: "success",
      isNotify: false,
      changeEnablePass: "",
      audienceId: "",
    };
  },

  computed: {
    areAllSelected() {
      return (
        this.selectedItems?.length === this.allAudienceData.items?.length &&
        this.allAudienceData.items?.length != 0
      );
    },
    columns() {
      var constField = [
        {
          field: "name",
          title: "Name",
          cell: "name",
        },
        {
          field: "email",
          title: "Email",
        },
        // {
        //   field: "FirstName",
        //   title: "First Name",
        // },
        // {
        //   field: "LastName",
        //   title: "Last Name",
        // },
        {
          field: "assign",
          title: "Assign",
          cell: "assign",
        },
      ];
      return constField;
    },

    allAudienceData() {
      return this.$store.state.allAudience.audiences;
    },
    fieldData() {
      return this.$store.state.allAudience.fields;
    },
    total() {
      return this.allAudienceData ? this.allAudienceData.size : 0;
    },
    take() {
      return this.allAudienceData ? this.allAudienceData.limit : 0;
    },
    skip() {
      return this.allAudienceData ? this.allAudienceData.offset : 0;
    },
    visibleModal() {
      return this.$store.state.club.modalVisible;
    },
    listData() {
      return this.$store.state.list.lists;
    },
    tagsData() {
      return this.$store.state.tag.tags;
    },
    isShowMsg() {
      return this.$store.state.common.showMsg;
    },
    showMsg() {
      return this.$store.state.common.status;
    },
    isLoading() {
      return this.$store.state.common.loader;
    },

    isShowSuccessMsg() {
      return this.$store.state.allAudience.showSuccessMsg;
    },
    showSuccessMsg() {
      return this.$store.state.allAudience.successStatus;
    },

    isShowErrorMsg() {
      return this.$store.state.allAudience.showErrorMsg;
    },
    showErrorMsg() {
      return this.$store.state.allAudience.errorStatus;
    },
    isLoadingModal() {
      return this.$store.state.allAudience.isLoading;
    },

    isShowImportMsg() {
      return this.$store.state.allAudience.showImportMsg;
    },
    showImportMsg() {
      return this.$store.state.allAudience.importStatus;
    },
    modalVisible() {
      return this.$store.state.allAudience.selectRecipientModal;
    },
  },
  mounted() {
    if (+this.$route.query.tagId) {
      let tagparam = {
        id: this.$route.query.tagId,
        text: this.$route.query.tagText,
      };
      this.passTagStatusValue = tagparam;
      this.pramsTagId = this.$route.query.tagId;
    }
    this.part;
    this.getAudience();
    // this.getAllList();
    // this.dropdownlistDatapass();
    // this.getTags();
    //this.getAllFields();
  },
  methods: {
    allcheckedHandle(val) {
      this.allChecked = false;
    },
    getAudience() {
      var model = new GetAudienceModel();
      model.clubId = this.$route.params.id;
      model.entityType = +this.$route.query.type;
      model.searchQuery = "";
      model.tagId = this.pramsTagId;
      model.limit = 10;
      this.$store.dispatch("getAudience", model);
    },
    dropdownlistDatapass() {
      for (const i in this.listData.items) {
        if (this.audienceDropDown.length == this.listData?.items?.length + 1) {
          return this.audienceDropDown;
        }
        let object = {
          id: this.listData.items[i].id,
          text: this.listData.items[i].name,
        };
        this.audienceDropDown?.push(object);
      }
    },
    dropdownTagDatapass() {
      for (const i in this.tagsData.items) {
        if (
          this.audienceTagDropDown.length ==
          this.tagsData?.items?.length + 1
        ) {
          return this.audienceTagDropDown;
        }
        let object = {
          id: this.tagsData.items[i].id,
          text: this.tagsData.items[i].name,
        };
        this.audienceTagDropDown?.push(object);
      }
    },
    onHeaderSelectionChange(event) {
      this.allChecked = event.event.target.checked;
      let allChecked = this.allChecked;
      this.allAudienceData.items = this.allAudienceData.items.map((item) => {
        return { ...item, selected: allChecked };
      });
      if (allChecked) {
        this.selectedItems = [];
        let members = this.allAudienceData.items;
        for (const key in members) {
          if (Object.hasOwnProperty.call(members, key)) {
            this.selectedItems.push(members[key].id);
            this.selectedMemberList.push(members[key]);
          }
        }
      } else {
        this.selectedItems = [];
      }
    },
    onSelectionChange(event) {
      event.dataItem[this.selectedField] = !event.dataItem[this.selectedField];
      this.selectedItems = event.dataItems
        .filter((x) => x.selected === true)
        .map((x) => x.id);

      this.selectedMemberList = event.dataItems.filter(
        (x) => x.selected === true
      );
    },
    debounceSearch(event) {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.onSearch(event);
      }, 600);
    },
    onSearch(e) {
      this.$store.commit("SET_LOADER", true);
      this.searchWord = e.value;

      var model = new GetAudienceModel();
      model.clubId = this.$route.params.id;
      model.entityType = +this.$route.query.type;
      model.searchQuery = this.searchWord;
      model.tagId = this.pramsTagId
        ? this.pramsTagId
        : this.passTagStatusValue?.id;
      model.listId = this.passListStatusValue.id;

      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.$store.dispatch("getAudience", model);
      }, 800);
    },
    dataStateChange: function (event) {
      this.take = event.data.take;
      this.skip = event.data.skip;

      var model = new GetAudienceModel();
      model.clubId = this.$route.params.id;
      model.entityType = +this.$route.query.type;
      model.searchQuery = this.searchWord;
      model.limit = event.data.take;
      model.offset = event.data.skip;
      model.tagId = this.pramsTagId
        ? this.pramsTagId
        : this.passTagStatusValue?.id;
      model.listId = this.passListStatusValue.id;
      this.$store.dispatch("getAudience", model);
    },
    createTag() {},
    handleListStatusValue(e) {
      this.passListStatusValue = e.value;
      var model = new GetAudienceModel();
      model.clubId = this.$route.params.id;
      model.entityType = +this.$route.query.type;
      model.searchQuery = "";
      model.listId = this.passListStatusValue.id;
      model.tagId = this.passTagStatusValue.id;
      this.$store.dispatch("getAudience", model);
    },
    handleTagStatusValue(e) {
      this.passTagStatusValue = e.value;
      var model = new GetAudienceModel();
      model.clubId = this.$route.params.id;
      model.entityType = +this.$route.query.type;
      model.searchQuery = "";
      model.listId = this.passListStatusValue.id;
      model.tagId = this.passTagStatusValue.id;
      this.$store.dispatch("getAudience", model);
    },
    newSegment() {},
    btnAddAudience() {
      this.$store.commit("MODAL_VISIBLE", true);
      this.$store.commit("SET_LOADER", false);
    },
    openModal() {
      this.modalVisible = true;
    },
    openImportModal() {
      this.importModalVisible = true;
      this.closeMemberImport = true;
    },
    save(data) {
      let clubId = this.$route.params.id;
      let model = {
        documentId: data,
        clubId: clubId,
      };
      this.$store.dispatch("importMember", model);
    },
    onChangeDrodownImportExport(e) {
      if (e.itemIndex == 1) {
        this.exportList();
      }
      if (e.itemIndex == 0) {
        this.openImportModal();
      }
    },
    async exportList() {
      this.$store.commit("SET_LOADER", true);
      let payload = {
        entityType: +this.$route.query.type,
        clubId: this.$route.params.id,
        tagId:
          this.passTagStatusValue?.id == 0 ? null : this.passTagStatusValue?.id,
        listId:
          this.passListStatusValue?.id == 0
            ? null
            : this.passListStatusValue?.id,
      };

      if (+this.$route.query.tagId) {
        payload = {
          entityType: +this.$route.query.type,
          clubId: this.$route.params.id,
          tagId: this.$route.query?.tagId,
          listId:
            this.passListStatusValue?.id == 0
              ? null
              : this.passListStatusValue?.id,
        };
      }
      let token = null;
      if (configuration.enableIdentityServer) {
        token = await auth.getAccessToken();
      } else {
        token = localStorage.getItem("clubeez_session");
      }

      const headers = {
        Authorization: `Bearer ${token}`,
      };
      axios({
        url: `${configuration.apiBaseUrl}/api/v1/Audience/export`,
        method: "POST",
        responseType: "blob",
        headers: headers,
        data: payload,
      })
        .then((response) => {
          this.$store.commit("SET_LOADER", false);
          this.showNotification("Audience exported successfully.", "success");
          let fileURL = window.URL.createObjectURL(new Blob([response.data]));
          let fileLink = document.createElement("a");
          fileLink.href = fileURL;
          let filename =
            +this.$route.query.type == 1
              ? "All audience of digital pass.xlsx"
              : "All audience of voucher.xlsx";
          fileLink.setAttribute("download", filename);
          document.body.appendChild(fileLink);
          fileLink.click();
        })
        .catch((error) => {
          this.$store.commit("SET_LOADER", false);
          var model = new SnackBarModel();
          model.type = "error";
          model.Message = "No audience to export.";
          this.$store.dispatch("showNotification", model);
        });
    },
    modalHandler(data) {
      this.$store.commit("SET_ALL_RECIPIENT_MODAL_OPEN", false);
    },
    getopenMemberMapping(value) {
      this.closeMemberImport = false;
      this.openMembermapping = value.isModalOpen;
      if (this.openMembermapping) {
        this.$store.commit("IMPORT_MODAL_VISIBLE", value.isModalOpen);
      }
      this.importedDocId = value.doccumentId;
    },
    getCloseMemberImportModal(value) {
      this.closeMemberImport = false;
    },
    getopenMemberImport(value) {
      this.closeMemberImport = value;
      this.openMembermapping = false;
    },
    closeAllModals() {
      this.importModalVisible = false;
      this.closeMemberImport = false;
      this.openMembermapping = false;
    },
    getClub(e) {
      // this.$store.dispatch("getClub", e);
    },
    openConFigureFields() {
      // this.isOpenConfigureFields = true;
      this.$router.push({
        name: "ConfigureFields",
        query: {
          type: +this.$route.query.type,
        },
      });
    },
    cancel() {
      this.isOpenConfigureFields = false;
    },
    getAllFields() {
      var payload = {
        clubId: this.$route.params.id,
        entityType: +this.$route.query.type,
      };
      this.$store.dispatch("getConfigureFields", payload);
    },
    deleteModal() {
      this.$store.commit("MODAL_VISIBLE_COMMON", true);
    },
    selectedLength(e) {
      if (e == 0) this.selectedItems = [];
    },
    showNotification(message, type) {
      this.notifyMessage = message;
      this.notifyType = type;
      this.isNotify = true;
      setTimeout(
        () => (
          (this.notifyMessage = ""),
          (this.notifyType = "success"),
          (this.isNotify = false)
        ),
        4000
      );
    },
    assignVoucherHandler() {
      const payload = {
        ...this.payloadForAssign,
        audienceId: this.audienceId,
        clubId: this.$route.params.id,
      };
      this.$store.dispatch("assignAudienceToVoucherCode", payload);
      this.$store.commit("SET_ALL_RECIPIENT_MODAL_OPEN", false);
    },
    assignStatusHandler(e) {
      this.audienceId = e.dataItem.id;
    },
    switchStatushandler(e) {
      if (!e.target.value) {
        setTimeout(() => {
          this.audienceId = "";
        }, 100);
      }
    },
  },
};
</script>

<style></style>
