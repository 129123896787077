<template src="./changeStatusModal.html"></template>

<script>
import { Button } from "@progress/kendo-vue-buttons";
import AppLoader from "@/components/common/AppLoader/AppLoader.vue";
import modal from "@/components/common/AppModal/AppModal.vue";
import { NumericTextBox } from "@progress/kendo-vue-inputs";
import { DatePicker } from "@progress/kendo-vue-dateinputs";
import AppSnackBar from "@/components/common/AppSnackBar/AppSnackBar.vue";
import { Input } from "@progress/kendo-vue-inputs";
import { DropDownList } from "@progress/kendo-vue-dropdowns";
export default {
  props: {
    payloadForaddUnassignedVoucher: {
      type: Object,
    },

    onCancel: { type: Function },
    onConfirm: { type: Function },
  },
  components: {
    kbutton: Button,
    "app-loader": AppLoader,
    modal,
    numericTextBox: NumericTextBox,
    datePicker: DatePicker,
    AppSnackBar,
    "k-input": Input,
    dropdownlist: DropDownList,
  },
  data() {
    return {
      notifyMessage: "",
      notifyType: "",
      isNotify: false,
      voucherStatusesValues: [
        { id: 2, text: "Active" },
        { id: 1, text: "Inactive" },

      ],
      voucherStatusValue: { id: 2, text: "Active" },
    };
  },
  methods: {
    handlevoucherStatusValue(e) {
      this.voucherStatusValue = e.value;
    },
    showNotification(message, type) {
      this.notifyMessage = message;
      this.notifyType = type;
      this.isNotify = true;
      setTimeout(
        () => (
          (this.notifyMessage = ""),
          (this.notifyType = "success"),
          (this.isNotify = false)
        ),
        4000
      );
    },
    dataValidation() {
      // if (this.isPastDate(this.voucherExpiryDate)) {
      //   this.showNotification(
      //     "You must select present or a future date.",
      //     "warning"
      //   );
      //   ;
      //   return false;
      // }

      return true;
    },
    onSubmitHandler() {
      if (this.dataValidation()) {
        this.onConfirm({
          status: this.voucherStatusValue,
        });
      }
    },
  },
};
</script>

<style></style>
