<modal
  :class="'max-height-100-145'"
  title="Change Voucher Expiry Date"
  :handler="true"
  @modalHandler="this.onCancel"
  :cssClass="'c-modal-small'"
>
  <template v-slot:content>
    <div>
      <div class="text-start">
        <label for="">Select a new expiry date</label>
        <datePicker
          :format="'dd-MMM-yyyy'"
          @change="this.updateExpiryDate"
          :min="newDatePlusOne"
          :value="this.voucherExpiryDate"
        >
        </datePicker>
      </div>
    </div>
  </template>
  <template v-slot:footer>
    <div class="d-flex justify-content-between">
      <div class="me-1 w-50-p">
        <kbutton @click="this.onCancel" :class="'w-100'">Cancel</kbutton>
      </div>
      <div class="ms-1 w-50-p">
        <kbutton
          @click="this.onSubmitHandler"
          :disabled="!this.voucherExpiryDate "
          :theme-color="'primary'"
          :class="'w-100'"
          >Change Date</kbutton
        >
      </div>
    </div>
  </template>
</modal>

<AppSnackBar
  :message="this.notifyMessage"
  :notificationType="this.notifyType"
  v-if="this.isNotify"
></AppSnackBar>
