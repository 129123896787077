<template src="./RefillVoucherAmountModal.html"></template>

<script>
import { Button } from "@progress/kendo-vue-buttons";
import modal from "@/components/common/AppModal/AppModal.vue";
import { NumericTextBox } from "@progress/kendo-vue-inputs";
import AppSnackBar from "@/components/common/AppSnackBar/AppSnackBar.vue";

export default {
  props: {
    payloadForaddUnassignedVoucher: {
      type: Object,
    },

    onCancel: { type: Function },
    onConfirm: { type: Function },
  },
  components: {
    kbutton: Button,
    modal,
    numericTextBox: NumericTextBox,
    AppSnackBar,
  },
  data() {
    return {
      voucherAmount: 50,
      notifyMessage: "",
      notifyType: "",
      isNotify: false,
    };
  },
  methods: {
    showNotification(message, type) {
      this.notifyMessage = message;
      this.notifyType = type;
      this.isNotify = true;
      setTimeout(
        () => (
          (this.notifyMessage = ""),
          (this.notifyType = "success"),
          (this.isNotify = false)
        ),
        4000
      );
    },
    dataValidation() {
      if (this.voucherAmount <= 0) {
        this.showNotification(
          "Voucher amount should be greater than 0.",
          "warning"
        );
        return false;
      }

      if (this.voucherAmount > 500) {
        this.showNotification(
          "Voucher amount should be less than or equal to 500.",
          "warning"
        );
        return false;
      }
      return true;
    },
    validateInput(event) {
      const value = event.value;
      if (value !== null && typeof value === 'number') {
        const valueStr = value.toString();
        const decimalIndex = valueStr.indexOf('.');

        if (decimalIndex !== -1 && valueStr.length - decimalIndex - 1 > 2) {
          this.voucherAmount = Math.round(value * 100) / 100;
        } else {
          this.voucherAmount = value;
        }
      }
    },
    onSubmitHandler() {
      if (this.dataValidation()) {
        this.onConfirm({
          refillAmount: this.voucherAmount,
        });
      }
    },
  },
};
</script>

<style></style>
