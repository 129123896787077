<template src="./ChangeExpiryDateModal.html"></template>

<script>
import { Button } from "@progress/kendo-vue-buttons";
import AppLoader from "@/components/common/AppLoader/AppLoader.vue";
import modal from "@/components/common/AppModal/AppModal.vue";
import { NumericTextBox } from "@progress/kendo-vue-inputs";
import { DatePicker } from "@progress/kendo-vue-dateinputs";
import AppSnackBar from "@/components/common/AppSnackBar/AppSnackBar.vue";
import { Input } from "@progress/kendo-vue-inputs";

export default {
  props: {
    payloadForaddUnassignedVoucher: {
      type: Object,
    },

    onCancel: { type: Function },
    onConfirm: { type: Function },
  },
  components: {
    kbutton: Button,
    "app-loader": AppLoader,
    modal,
    numericTextBox: NumericTextBox,
    datePicker: DatePicker,
    AppSnackBar,
    "k-input": Input,
  },
  data() {
    let currDate = new Date();
    currDate.setDate(currDate.getDate() + 7);
    return {
      voucherExpiryDate: currDate,
      notifyMessage: "",
      notifyType: "",
      isNotify: false,
      currentDate: new Date(),
    };
  },
  computed: {
    newDatePlusOne() {
      const currentDate = new Date(this.currentDate);
      currentDate.setDate(currentDate.getDate() + 1);
      return currentDate;
    },
  },
  methods: {
    updateExpiryDate(event) {
      this.voucherExpiryDate = event.target.value;
    },
    showNotification(message, type) {
      this.notifyMessage = message;
      this.notifyType = type;
      this.isNotify = true;
      setTimeout(
        () => (
          (this.notifyMessage = ""),
          (this.notifyType = "success"),
          (this.isNotify = false)
        ),
        4000
      );
    },
    isPastDate(date) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      date.setHours(0, 0, 0, 0);
      return date < today;
    },
    dataValidation() {
      if (this.isPastDate(this.voucherExpiryDate)) {
        this.showNotification(
          "You must select present or a future date.",
          "warning"
        );
        ;
        return false;
      }

      return true;
    },
    onSubmitHandler() {
      if (this.dataValidation()) {
        this.onConfirm({
          expiryDate: this.voucherExpiryDate,
        });
      }
    },
  },
};
</script>

<style></style>
