<template src="./BulkActionSnacbar.html"></template>

<script>
import { Button } from "@progress/kendo-vue-buttons";
import modal from "@/components/common/AppModal/AppModal.vue";

export default {
  components: {
    modal,
    kbutton: Button,
  },

  props: {
    modalDetails: {
      required: false,
    },
    onCancel: { type: Function },
  },

  data: function () {
    return {};
  },

  computed: {},

  methods: {},
};
</script>

<style></style>
