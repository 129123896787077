<template src="./MapDataToVoucherFieldsModal.html"></template>

<script>
import { Button } from "@progress/kendo-vue-buttons";
import AppLoader from "../../../../common/AppLoader/AppLoader.vue";
import { DropDownList } from "@progress/kendo-vue-dropdowns";
import { filterBy } from "@progress/kendo-data-query";
import modal from "../../../../common/AppModal/AppModal.vue";
import PassMapModel from "../../../../../models/digitalPass/PassMapModel";
import { Tooltip } from "@progress/kendo-vue-tooltip";
import { importVoucherFieldsMappedDataService } from "../../../../../services/clubDetails/voucherManageRecipients.service";
import SnackBarModel from "@/models/snackBar/snackBarModel";

export default {
  components: {
    Tooltip,
    kbutton: Button,
    "app-loader": AppLoader,
    dropdownlist: DropDownList,
    modal,
  },
  props: {
    documentId: {
      type: String,
      default: "",
    },
  },
  data: function () {
    return {
      mapConfig: [],
      clubId: this.$route.params.id,
      memberImportModalVisible: false,
      closeMapModal: true,
      searchData: "",
      dValue: "",
      canSave: true,
      emailError: "Mapping is required",
    };
  },
  computed: {
    columns() {
      let result = this.$store.state.manageRecipients.columnList;
      return result.filter((x) => x != this.dValue);
    },
    voucherFieldsAttributes() {
      const attributes = [
        {
          column: "email",
          label: "Email",
          mappable: true,
          isRequired: true,
        },
        {
          column: "voucherExpiryDate",
          label: "Voucher Expiry Date",
          mappable: true,
          isRequired: false,
        },
        {
          column: "voucherAmount",
          label: "Voucher Amount",
          mappable: true,
          isRequired: false,
        },
        // {
        //   column: "voucherQuantity",
        //   label: "Voucher Quantity Per User",
        //   mappable: true,
        //   isRequired: false,
        // },
      ];
      return attributes;
    },
    attributeMap() {
      return this.$store.state.manageRecipients.attributeMap;
    },
  },
  mounted() {
    this.getColumns(this.documentId);
  },

  methods: {
    onOpen(e) {
      this.dValue = e.value;
      this.searchData = "";
      this.getColumns(this.documentId);
    },
    onClose() {
      this.searchData = "";
    },
    filterChange(event) {
      this.searchData = "";
      let data = this.$store.state.manageRecipients.columnModifier;
      this.searchData = filterBy(data, event.filter);
      this.$store.commit("SET_COLUMNS", this.searchData);
    },
    getColumns(id) {
      let payload = {
        clubId: this.$route.params.id,
        documentId: id
      }
      this.$store.dispatch("getPassColumns", payload);
    },
    handleMap(item, e) {
      this.searchData = "";
      this.emailError = "";
      let source = 1;
      this.mapConfig = this.mapConfig.filter((x) => x.column != item);
      if (e.value != "Select Column") {
        let map = new PassMapModel();
        map.column = item;
        map.mapFrom = e.value;
        map.source = source;
        this.mapConfig.push(map);
      }
      if (this.mapConfig.filter((x) => x.column == "email").length) {
        this.canSave = true;
      }
    },
    saveData() {
      this.canSave = true;

      let result = this.mapConfig.filter((x) => x.column == "email");
      if (!result.length) {
        this.canSave = false;
      }

      if (!this.canSave) {
        return;
      }

      let payload = {
        documentId: +this.documentId,
        clubId: +this.clubId,
        voucherCampaignId: +this.$route.query.voucherCampaignId,
        mappingConfig: this.mapConfig,
      };

      this.$emit("closeAllModals");
      this.$emit("closeAllModalsAdd");
      this.$store.commit("LOADING_STATUS", true);

      let payloadForVoucherRecipients = {
        clubId: +this.clubId,
        voucherCampaignId: +this.$route.query.voucherCampaignId,
      };
      importVoucherFieldsMappedDataService(payload)
        .then(() => {
          this.$store.commit("LOADING_STATUS", false);
          let model = new SnackBarModel();
          model.type = "success";
          model.Class = "status-error";
          model.Message = "Data for Voucher has been imported successfully.";
          this.$store.commit("SET_CONTROL_STATUS", model);
          this.$store.commit("SET_SHOW_MESSAGE", true);
          setTimeout(() => {
            this.$store.commit("SET_SHOW_MESSAGE", false);
          }, 5000);
          this.$store.dispatch(
            "getVoucherRecipients",
            payloadForVoucherRecipients
          );
        })
        .catch(() => {
          this.$store.commit("LOADING_STATUS", false);
          const model = new SnackBarModel();
          model.type = "error";
          model.Message = "Error in importing data for Voucher.";
          this.$store.commit("SET_CONTROL_STATUS", model);
          this.$store.commit("SET_SHOW_MESSAGE", true);
          setTimeout(() => {
            this.$store.commit("SET_SHOW_MESSAGE", false);
          }, 5000);
        });
    },
    modalHandler() {
      this.$emit("closeMapModal");
      this.$emit("closeMapModalAdd");
    },
  },
};
</script>
