<!--dashboard-content-->
<intl>
  <modal title="Upload Data for Voucher Fields" :handler="true" :cssClass="'c-modal-md'" @modalHandler="modalHandler">
    <template v-slot:content>
      <div class="radius-5 d-flex justify-content-center align-items-center">
        <div class="text-center">
          <div class="width-482 mx-auto">
            <div class="file-upload-wrap file-upload-custom-main border-gray">
              <div class="custom-upload-box">
                <span class="k-icon k-i-upload font-42 color-blue mb-3"></span>

                <span class="font-16 color-blue mt-2">
                  <span>
                    {{importFileName ?
                    `${isNameLimit(importFileName)}` :
                    "Drag or Choose file to Import Data for Voucher Fields"
                    }}
                  </span>
                  <kbutton v-if="importMembersDocId" :class="'small-btn-icon'" @click="removeDocument">
                    <span class="k-icon k-i-close"></span>
                  </kbutton>
                </span>
              </div>

              <upload :disabled="isDisabled" :batch="false" :multiple="false" :files="files" @add="onAdd"
                @remove="onRemove" @progress="onProgress" @statuschange="onStatusChange" :with-credentials="false"
                :save-url="uploadDocument" :restrictions="{allowedExtensions}" :accept="allowedExtensions"></upload>
              <div class="error-msg text-center w-100">{{validationErrors}}</div>
            </div>
          </div>
        </div>
      </div>
    </template>

    <template v-slot:footer>
      <div class="d-flex justify-content-end">
        <kbutton @click='modalHandler' class="me-3">Cancel</kbutton>
        <kbutton :disabled="!importMembersDocId" :theme-color="'primary'" @click="handleFieldMapping"> Continue
          to Field Mapping</kbutton>
      </div>
    </template>

  </modal>
</intl>