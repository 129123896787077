<modal
  :title="'Generating Voucher Codes...'"
  :handler="true"
  :cssClass="'c-modal-lg snack-bar-popup justify-content-start'"
  @modalHandler="modalHandler"
>
  <template v-slot:content>
    <p class="text-center mb-2 pb-1 font-14">
      Voucher codes are being generated and this may take a while. Please wait
      while the codes are being generated or you can come back later to check
      updates.
    </p>
  </template>
</modal>
