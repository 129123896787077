<modal
  :class="'max-height-100-145'"
  title="Add Voucher"
  :handler="true"
  @modalHandler="modalHandler"
  :cssClass="'c-modal-small'"
>
  <template v-slot:content>
    <div class="d-flex mb-3">
      <span class="min-w-24 me-2 mt-1">
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M22.5 12C22.5 17.7984 17.7984 22.5 12 22.5C6.20156 22.5 1.5 17.7984 1.5 12C1.5 6.20156 6.20156 1.5 12 1.5C17.7984 1.5 22.5 6.20156 22.5 12ZM21 12C21 7.03125 16.9688 3 12 3C7.03125 3 3 7.03125 3 12C3 16.9688 7.03125 21 12 21C16.9688 21 21 16.9688 21 12ZM13.5 9V6H10.5V9H13.5ZM13.5 16.5H15V18H9V16.5H10.5V12H9V10.5H13.5V16.5Z"
            fill="#0052CC"
          />
        </svg>
      </span>
      <p class="font-15 l-height-24">
        Vouchers you add here will be unassigned initially. You must assign them
        to specific members before sending email.
      </p>
    </div>

    <div>
      <div class="text-start">
        <label for="">How many vouchers do you want to add?</label>
        <k-input type="number" v-model="voucherQuantity" :min="1" :max="500">
        </k-input>
      </div>
      <div class="text-start">
        <label for="">Voucher Amount?</label>
        <k-input type="number" v-model="voucherAmount" :min="1" :max="500">
        </k-input>
      </div>
      <div class="text-start">
        <label class="font-16">Prefix</label>
        <k-input
          type="text"
          v-model="voucherPrefix"
          placeholder="Enter Voucher Prefix"
          aria-required="true"
        >
        </k-input>
      </div>
      <div class="text-start">
        <label for="">Expiry Date</label>
        <datePicker
          :format="'dd-MMM-yyyy'"
          @change="updateExpiryDate"
          :min="newDatePlusOne"
          :value="voucherExpiryDate"
        >
        </datePicker>
      </div>
      <div class="text-start">
        <label>Voucher Status</label>
        <dropdownlist
          :class="'w-100-vsm mb-24'"
          :style="{  }"
          :data-items="voucherStatusesValues"
          :text-field="'text'"
          :data-item-key="'id'"
          :value="voucherStatusValue"
          @change="handlevoucherStatusValue"
        >
        </dropdownlist>
      </div>
    </div>
  </template>
  <template v-slot:footer>
    <div class="d-flex justify-content-between">
      <div class="me-1 w-50-p">
        <kbutton @click="modalHandler" :class="'w-100'">Cancel</kbutton>
      </div>
      <div class="ms-1 w-50-p">
        <kbutton
          @click="addVoucherCodeHandler"
          :disabled="!voucherAmount || !voucherQuantity || !voucherExpiryDate"
          :theme-color="'primary'"
          :class="'w-100'"
          >Add</kbutton
        >
      </div>
    </div>
  </template>
</modal>

<AppSnackBar
  :message="notifyMessage"
  :notificationType="notifyType"
  v-if="isNotify"
></AppSnackBar>
